import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomTableHeader from "shared/components/customTableHeader";
import LocationRequestSkeleton from "shared/skeletons/locationRequestsSkeleton";
import CustomTooltip from "shared/components/toolTip";
import Pagination from "shared/components/pagination";
import NoDataFound from "shared/components/noDataFound";

import Confirmation from "shared/modals/approveRejectModal";

import { columnHeadings, actions, sortingOptions } from "./constants";
import { Icons } from "assets";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { getRequestedLocations } from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import { handleOpenInNewTab } from "shared/utils/helpers";

import styles from "./style.module.scss";
import useDebounce from "shared/customHooks/useDebounce";

const LocationRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [filter, setFilter] = useState<number | null>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [addNewPinModal, setAddNewPinModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [requestedLocations, setRequestedLocations] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<any>(null);
  const [confirmAction, setConfirmAction] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const handleApproveLocation = (item: any) => {
    setModalType("Approve");
    setLocationId(item?._id);
    setOpenApproveModal(true);
  };
  const handleRejectLocation = (item: any) => {
    setModalType("Reject");
    setLocationId(item?._id);
    setOpenRejectModal(true);
  };
  const handleViewLocation = (item: any) => {
    handleOpenInNewTab(
      `${routeConstant?.place_details.path.replace(":id", item?._id)}`
    );
  };

  const handlers: { id: string; handler: (item: any) => void }[] = [
    { id: "approve_location", handler: handleApproveLocation },
    { id: "reject_location", handler: handleRejectLocation },
    { id: "view_location", handler: handleViewLocation },
  ];

  // Get Requested Locations

  const getRequestedLocation = () => {
    setLoader(true);
    getRequestedLocations({ activePage, itemsPerPage: 10, search: searchText })
      .then(({ status, data }) => {
        if (status) {
          const { count, locations } = data;
          setRequestedLocations(locations);
          setLoader(false);
          setConfirmAction(false);
          setTotalPages(count);
        }
      })
      .catch((err) => {
        toastMessage("err", "Failed to Load requested locations");
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Location",
            action: () => {
              navigate(routeConstant.locations.path);
            },
          },
          {
            title: "Location Requests",
            action: () => {
              navigate(routeConstant.location_requests.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getRequestedLocation();
  }, [filter, confirmAction, searchText]);

  return (
    <section>
      <div
        onClick={() => navigate(-1)}
        className="flex gap-2 mb-8 hover:cursor-pointer text-GRAY"
      >
        <span>&#8592;</span>
        <span>Back</span>
      </div>
      {/* Custom Table Header */}
      <CustomTableHeader
        modalButtonText="Add New Pin"
        showFilter={false}
        sortingOptions={sortingOptions}
        sortText={filter}
        heading="All Locations Request List"
        subHeading="Showing the list of all locations"
        search={search}
        setActivePage={setActivePage}
        setSearch={setSearch}
        setSort={setFilter}
        showModalButton={true}
        setOpenModal={setAddNewPinModal}
      ></CustomTableHeader>

      {/* Custom Table */}
      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50 ">
              <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                {columnHeadings.map((column: any) => {
                  return (
                    <th
                      key={column.id}
                      scope="col"
                      className="px-6 py-3 text-xs font-medium text-GRAY"
                    >
                      {column.value}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {loader ? (
                Array.from({ length: 4 }).map(() => (
                  <LocationRequestSkeleton actions={3} />
                ))
              ) : (
                <>
                  {requestedLocations?.length > 0
                    ? requestedLocations?.map((item: any) => {
                        return (
                          <tr
                            key={item?.locationId}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY    border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="w-12 h-12 rounded-lg">
                                  <img
                                    alt={item?.title}
                                    loading="lazy"
                                    className="w-full h-full rounded-lg object-cover"
                                    src={
                                      item?.pictures ? item?.pictures[0] : ""
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs leading-[19px] text-TABLE_BODY_TEXT_GRAY md:text-sm">
                                  {item?.title ? item?.title : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-72 ">
                              <div className="flex items-center gap-[10px]">
                                <span>
                                  <Icons.Address />
                                </span>
                                <span className="text-xs leading-[19px] text-GRAY font-medium md:text-sm">
                                  {item?.address
                                    ? item?.address
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row items-center gap-2">
                                <figure className="h-10 w-10 rounded-full">
                                  <img
                                    className="w-full h-full rounded-full object-cover"
                                    alt={item?.title}
                                    src={
                                      item?.created_by
                                        ? item?.created_by
                                            ?.profile_picture_full_path
                                        : null
                                    }
                                    loading="lazy"
                                  />
                                </figure>
                                <span className="text-xs font-medium text-[#101828] md:text-sm">
                                  {item?.created_by
                                    ? `${item?.created_by.firstname} ${item?.created_by?.lastname}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <p className="flex flex-row gap-2 items-center">
                                {actions?.map((action: any, index) => {
                                  const handler = handlers?.find(
                                    (handler) => handler?.id === action?.id
                                  )?.handler;
                                  return (
                                    <span
                                      className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                      key={action.id}
                                      onClick={() => handler?.(item)}
                                    >
                                      <CustomTooltip
                                        content={action?.title}
                                        icon={action.icon}
                                        index={index}
                                      />
                                    </span>
                                  );
                                })}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* NO Data Found */}
        {requestedLocations?.length === 0 && !loader && (
          <NoDataFound icon={<Icons.NoLocation />} />
        )}
        {/* Pagination */}
        {requestedLocations?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>
      <Confirmation
        setConfirmAction={() => setConfirmAction(true)}
        locationId={locationId}
        text={
          modalType === "Reject"
            ? "Are you sure you want to reject this listing?"
            : "Are you sure you want to approve this listing?"
        }
        viewModal={modalType === "Reject" ? openRejectModal : openApproveModal}
        setCloseModal={
          modalType === "Reject"
            ? () => setOpenRejectModal(false)
            : () => setOpenApproveModal(false)
        }
        type={modalType}
      />
    </section>
  );
};

export default LocationRequests;
