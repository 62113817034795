import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "../reducers/userSlice";
import breadcrumbSlice from '../reducers/breadcrumbSlice';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userSlice", "breadcrumbSlice"],
};

const rootReducer = combineReducers({
    userSlice,
    breadcrumbSlice
});
const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: {
        root: persistedReducer,
    },
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

});

const persistor = persistStore(store);
export { store, persistor };

