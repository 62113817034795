import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";

import useWindowSize from "shared/customHooks/windowSize";

import Header from "shared/components/header";
import styles from "./style.module.scss";
// import Header from "../header";

import { adminRoutes } from "./constant";
import { Icons } from "assets";
import classNames from "classnames";

interface SidebarProps {
  Component: any;
  path: any;
}

const SidebarComp = ({ Component, path }: SidebarProps) => {
  const width = useWindowSize();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const user = useSelector((state: any) => state.root.userSlice);
  const navigate = useNavigate();

  const handleNavigation = (item: any) => {
    navigate(item?.path);
    if (width < 992) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (width > 992) {
      setToggle(true);
    } else {
      setCollapse(true);
      setToggle(false);
    }
  }, [width]);

  return (
    <div className={classNames("flex", styles.topContainer)}>
      <Sidebar
        onBackdropClick={() => setToggle(false)}
        toggled={toggle}
        breakPoint={"lg"}
        // collapsed={collapse}
        backgroundColor="white"
      >
        <Menu>
          <div className="flex items-center justify-between pl-[20px] py-[13px] pr-[14px] cursor-pointer bg-white">
            <div className={styles.logoContainer}>
              <Link to="/dashboard">
                <Icons.Logo className={styles.logo} />
              </Link>
            </div>
            <div className="block lg:hidden" onClick={() => setToggle(false)}>
              <Icons.DuplicateBackArrows />
            </div>
          </div>
          <p className="text-GRAY uppercase px-5 pt-6 pb-2  text-sm font-medium leading-[16.8px] tracking-[1px]">
            MAIN
          </p>
          {adminRoutes?.map((item: any, inx: any) => {
            return (
              <React.Fragment key={item?.title}>
                {item?.path ? (
                  <MenuItem
                    onClick={() => handleNavigation(item)}
                    icon={item?.icon}
                    className={`${styles.menuItem} ${
                      path === item?.path ? styles.active : ""
                    }`}
                  >
                    {item?.title}
                  </MenuItem>
                ) : (
                  <SubMenu
                    key={item?.title}
                    label={item?.title}
                    icon={item?.icon}
                    className={styles.subMenuContainer}
                  >
                    {item?.subMenu?.map(
                      (subItem: any, inx: any, array: any) => {
                        return (
                          <MenuItem
                            key={subItem?.path}
                            onClick={() => handleNavigation(subItem)}
                            className={classNames(
                              styles.menuItem,
                              path === subItem?.path && array.length !== inx + 1
                                ? `${styles.subMenuActive} ${styles.borderSubMenu}`
                                : array.length !== inx + 1
                                ? styles.borderSubMenu
                                : path === subItem?.path
                                ? styles.subMenuActive
                                : ""
                            )}
                          >
                            {subItem?.title}
                          </MenuItem>
                        );
                      }
                    )}
                  </SubMenu>
                )}
              </React.Fragment>
            );
          })}
        </Menu>
      </Sidebar>

      <main className={classNames(styles.mainContainer, "w-full")}>
        <Header setMenuToggle={setToggle} />
        {/* max-w-screen-xl */}
        <div className="p-4 md:p-10 ">
          <Component />
        </div>
      </main>

      {loader && <div>Loading...</div>}
    </div>
  );
};

export default SidebarComp;
