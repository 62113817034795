import React from "react";

import { Icons, Images } from "assets";

interface AmenityCardProps {
  amenity: any;
  setSelectedAmenity: any;
  openDeleteModal: any;
  openEditAmenity: any;
}

const AmenityCard = ({
  amenity,
  setSelectedAmenity,
  openDeleteModal,
  openEditAmenity,
}: AmenityCardProps) => {
  return (
    <div className=" border border-LOCATION_CARD_BORDER rounded-lg">
      <div className="grid place-items-center w-24 h-24 rounded-2xl bg-ICON_BACKGROUND_GRAY mx-auto mt-[14px] mb-2">
        {/* Image Wrapper */}
        <figure className=" w-12 h-12">
          <img
            className="w-full h-full object-cover block bg-transparent"
            src={amenity?.avatar ? amenity?.avatar : Images.BlankProfile}
            alt={amenity?.avatar && amenity?.name}
          />
        </figure>
      </div>
      <div className="font-medium text-sm text-center text-BLACK mb-4 leading-5 md:text-base">
        <span>{amenity?.name ? amenity?.name : "Not Provided"}</span>
      </div>
      <div className="grid grid-cols-2 border-t border-LOCATION_CARD_BORDER">
        <div
          onClick={() => {
            openEditAmenity(true);
            setSelectedAmenity(amenity);
          }}
          className=" py-[14px] flex justify-center  cursor-pointer items-center gap-[6px] text-center font-medium text-xs text-SECONDARY border-r border-LOCATION_CARD_BORDER"
        >
          <span className=" w-4 h-4">
            <Icons.EditAmenity />
          </span>
          <span> Edit</span>
        </div>
        <div
          onClick={() => {
            setSelectedAmenity(amenity);
            openDeleteModal(true);
          }}
          className=" py-[14px] flex justify-center gap-[6px] cursor-pointer items-center text-center font-medium text-xs text-SECONDARY"
        >
          <span className=" w-4 h-4">
            <Icons.Trash />
          </span>
          <span> Delete</span>
        </div>
      </div>
    </div>
  );
};

export default AmenityCard;
