import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  getPrivacyPolicy,
  editPrivacyPolicy,
} from "shared/services/adminService";
import { modules, formats } from "../termsAndConditions/constants";
import { toastMessage } from "shared/components/toast";
import SkeletonLoader from "pages/termsAndConditions/skeletonLoader";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import CustomButton from "shared/components/customButton";
import { useDispatch } from "react-redux";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [privacyPolicyText, setPrivacyPolicyText] = useState<string>("");
  const [loader, setLoader] = useState(false);

  const handleUpdate = () => {
    let obj: any = {
      text: privacyPolicyText,
    };
    let formBody = new FormData();

    Object.keys(obj).forEach((key) => {
      formBody.append(key, obj[key]);
    });
    setLoader(true);
    editPrivacyPolicy(formBody)
      .then(({ status, data }) => {
        if (status) {
          setLoader(false);
          toastMessage("success", "Privacy Policy Updated Successfully");
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        toastMessage("error", err?.message);
        setLoader(false);
      });
  };

  const getPolicy = () => {
    setLoader(true);
    getPrivacyPolicy()
      .then(({ data, status }) => {
        if (status) {
          setPrivacyPolicyText(data?.text);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        toastMessage("error", err?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Privacy Policy",
            action: () => {
              navigate(routeConstant?.privacyPolicy.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getPolicy();
  }, []);

  return (
    <section>
      <div>
        <h1 className="font-semibold tracking-[0.12px] leading-8 text-xl mb-4">
          Privacy Policy
        </h1>
      </div>
      <div>
        {loader ? (
          <SkeletonLoader />
        ) : (
          <ReactQuill
            value={privacyPolicyText}
            onChange={(value) => setPrivacyPolicyText(value)}
            modules={modules}
            formats={formats}
          />
        )}
      </div>
      <div className="flex my-4 justify-end items-center">
        <CustomButton loading={loader} title="Update" onClick={handleUpdate} />
      </div>
    </section>
  );
};

export default PrivacyPolicy;
