import React, { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { Icons } from "assets";
import { addReviewSchema } from "shared/utils/validation";
import { editReview, addReview } from "shared/services/adminService";

import styles from "./style.module.scss";
import Rating from "shared/components/starRating";
import Spinner from "shared/components/spinner";
import { toastMessage } from "shared/components/toast";

interface ReviewModalProps {
  isOpen: boolean;
  close: any;
  edit: boolean;
  review: any;
  getReviews: any;
  view: boolean;
}

const ReviewModal = ({
  isOpen,
  close,
  edit,
  review,
  getReviews,
  view,
}: ReviewModalProps) => {
  const [rating, setRating] = useState<number>(1);
  const [file, setFile] = useState<any>(null);

  const initialValues = {
    image: null,
    review: "",
    rating: rating,
    userName: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addReviewSchema,
    onSubmit: () => handleReview(),
  });

  const handleReview = () => {
    formik.setSubmitting(true);
    const payload: any = {
      name: formik.values.userName,
      ratings: rating,
      message: formik.values.review,
    };
    const formBody = new FormData();
    Object.keys(payload).forEach((key) => {
      formBody.append(key, payload[key]);
    });
    if (file) {
      formBody.append("picture", file); // Append the image file
    }

    const service = edit ? editReview : addReview;

    service(formBody, edit ? review._id : null)
      .then(({ status, data }) => {
        if (status) {
          formik.setSubmitting(false);
          getReviews();
          close();
          if (!edit) {
            formik.resetForm();
            setRating(0);
          }

          toastMessage("success", data);
        }
      })
      .catch((err) => {
        formik.setSubmitting(false);
        toastMessage("error", "Something went wrong !");
        console.log("Error----", err);
      });
  };

  const handleClick = () => {
    document?.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    formik.setFieldValue("image", imageUrl);
  };

  useEffect(() => {
    if (edit || view) {
      formik.setFieldValue("image", review?.picture);
      formik.setFieldValue("userName", review?.name);
      formik.setFieldValue("review", review?.message);
      formik.setFieldValue("rating", review?.ratings);
      setRating(review?.ratings);
    } else {
      formik.setFieldValue("image", null);
      formik.setFieldValue("userName", "");
      formik.setFieldValue("review", "");
      formik.setFieldValue("rating", rating);
      setRating(0);
    }
  }, [edit, review._id, view]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => {
          if (!edit) {
            formik.resetForm();
            setFile(null);
          }
          close();
          setRating(0);
        }}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}

        <div className="fixed inset-0 flex w-screen items-center justify-center py-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              id={styles.panel}
              className="mx-auto max-w-[90vw] w-[500px] h-[90vh]  overflow-scroll pb-4 rounded-md bg-white md:max-w-lg"
            >
              <Dialog.Title className="relative">
                <span className="block py-4 text-center text-sm font-semibold leading-5 text-BLACK">
                  {edit ? "Edit Review" : view ? "Review" : "Add Review"}
                </span>
                <span
                  onClick={close}
                  className={`${styles.iconWrapper} absolute right-3 top-1 p-2 cursor-pointer`}
                >
                  <Icons.Cross />
                </span>
              </Dialog.Title>
              <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
              <Dialog.Description className=" mt-4">
                <form className=" px-4" onSubmit={(e) => e.preventDefault()}>
                  {!view && (
                    <div
                      onClick={handleClick}
                      className=" h-36 border-dashed border border-LOCATION_CARD_BORDER rounded-lg grid place-items-center cursor-pointer"
                    >
                      <p className="font-normal text-xs leading-4 md:text-sm max-w-32 text-center">
                        <span className=" text-SECONDARY font-medium">
                          Upload photo
                        </span>{" "}
                        or just drag and drop
                        <input className="hidden focus:outline-none"></input>
                      </p>
                      <input
                        key={
                          formik.values.image
                            ? formik.values.image
                            : "fileInput"
                        }
                        id="fileInput"
                        name="image"
                        onBlur={formik.handleBlur}
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  )}
                  {edit && formik?.values?.image ? (
                    <div className=" w-12 h-12 relative mt-2 ">
                      <span
                        onClick={() => {
                          formik.setFieldValue("image", null);
                        }}
                        className="absolute right-0 cursor-pointer "
                      >
                        <Icons.Cross />
                      </span>
                      <img
                        className=" w-full h-full object-cover block rounded-lg"
                        src={
                          formik?.values?.image
                            ? formik?.values?.image
                            : review?.picture
                        }
                        alt={review?.name}
                      />
                    </div>
                  ) : view ? (
                    <div className="w-16 h-16 mx-auto">
                      <img
                        className=" w-full h-full rounded-full block object-cover"
                        alt="user-image"
                        src={review?.picture}
                      />
                    </div>
                  ) : (
                    formik?.values.image && (
                      <div className=" w-12 h-12 relative mt-2 ">
                        <span
                          onClick={() => {
                            formik.setFieldValue("image", "");
                          }}
                          className="absolute right-0 cursor-pointer "
                        >
                          <Icons.Cross />
                        </span>
                        <img
                          className=" w-full h-full object-cover block rounded-lg"
                          src={
                            formik?.values?.image ? formik?.values?.image : ""
                          }
                          alt={formik?.values.userName}
                        />
                      </div>
                    )
                  )}
                  {formik.errors.image && formik.touched.image && !view && (
                    <span className="text-xs text-red-500 font-medium md:text-sm">
                      {formik.errors.image}
                    </span>
                  )}
                  <div className="flex flex-col mt-5 gap-2">
                    <label htmlFor="userName">User Name</label>
                    <input
                      disabled={view ? true : false}
                      name="userName"
                      id="userName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className=" text-xs font-normal p-4 border border-LOCATION_CARD_BORDER rounded-lg focus:outline-none md:text-sm"
                      placeholder="Enter User Name"
                      value={formik?.values?.userName}
                    />
                    {formik.errors.userName &&
                      formik.touched.userName &&
                      !view && (
                        <span className="text-xs text-red-500 font-medium md:text-sm">
                          {formik.errors.userName}
                        </span>
                      )}
                  </div>
                  <div className=" mt-5 flex flex-col gap-4">
                    <label htmlFor="rating">Rating</label>
                    {/* Rating */}
                    {view ? (
                      <div className="flex gap-1">
                        {Array.from({ length: 5 }).map((_, index) => {
                          if (index < review?.ratings) {
                            return (
                              <svg
                                key={index}
                                className="w-4 h-4 text-SECONDARY me-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            );
                          } else
                            return (
                              <svg
                                key={index}
                                className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            );
                        })}
                      </div>
                    ) : (
                      <Rating
                        id="rating"
                        currentValue={rating}
                        setRating={setRating}
                      />
                    )}
                    {formik.errors.rating && formik.touched.rating && !view && (
                      <span className="text-xs text-red-500 font-medium md:text-sm">
                        {formik.errors.rating}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col mt-5 gap-2">
                    <label htmlFor="review">Review</label>
                    <textarea
                      disabled={view ? true : false}
                      name="review"
                      id="review"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className=" h-28 resize-none overflow-scroll text-xs font-normal p-2 overflow-x-hidden border border-LOCATION_CARD_BORDER rounded-lg focus:outline-none md:text-sm"
                      placeholder="Enter review here"
                      value={formik?.values?.review}
                    />

                    {formik?.values?.review?.length < 3000 && !view && (
                      <span className="text-xs font-medium font-neueMedium text-GRAY">
                        * Maximum 3000 characters
                      </span>
                    )}

                    {formik.errors.review && formik.touched.review && !view && (
                      <span className="text-xs text-red-500 font-medium md:text-sm">
                        {formik.errors.review}
                      </span>
                    )}
                  </div>
                  {!view && (
                    <button
                      onClick={() => formik.handleSubmit()}
                      type="submit"
                      className=" mt-4 rounded-lg w-full text-center border-none py-4 bg-PRIMARY font-medium leading-5 text-white text-sm md:text-base hover:opacity-80 transition-all ease-linear duration-100"
                    >
                      {formik.isSubmitting ? (
                        <Spinner />
                      ) : (
                        <span>
                          <span>{edit ? "Update" : "Add"}</span>
                        </span>
                      )}
                    </button>
                  )}
                </form>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReviewModal;
