import React from "react";

const GeneralSkeleton = ({ className }: any) => {
  return (
    <div role="status" className=" animate-pulse">
      <div className={className}></div>
    </div>
  );
};

export default GeneralSkeleton;
