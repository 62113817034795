import * as yup from "yup";

const passwordRegExp = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/;

const LoginVS = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email")
    .label("email"),
  password: yup.string().required("Password is Required").label("password"),
});

const RegisterVS = yup.object().shape({
  fullname: yup.string().required("Full Name is Required").label("fullname"),
  profilePic: yup
    .mixed()
    .required("Profile Pic is Required")
    .label("profilePic"),
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email")
    .label("email"),
  password: yup
    .string()
    .required("Password is Required")
    .matches(
      passwordRegExp,
      "Password must contain at least One Upper Case Character, One Lower Case Character, One Special Character and One Number"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const accountInfoValidationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^\+[1-9]\d{1,14}$/, "Invalid phone number format"),
  address: yup.string().required("Address is Required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("validFileOrUrl", "Unsupported file format or invalid URL", (value) => {
      if (value instanceof File) {
        // Check if the value is a valid file
        const supportedFormats = ["image/jpeg", "image/png", "image/gif"];
        return supportedFormats.includes(value.type) && value.size <= 5 * 1024 * 1024; // 5MB
      } else if (typeof value === 'string') {
        return true;
      }
      return false; // Neither a valid file nor a valid URL
    }),

});

const passwordValidationSchema = yup.object().shape({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
});

const addAmenitySchema = yup.object().shape({
  title: yup.string().required("Title is required").max(200, "Title is too long"),
  image: yup
    .mixed()
    .required("Image is required").test("validFileOrUrl", "Unsupported file format or invalid URL", (value) => {
      if (value instanceof File) {
        const supportedFormats = ["image/jpeg", "image/png", "image/gif"];
        return supportedFormats.includes(value.type) && value.size <= 5 * 1024 * 1024;
      } else if (typeof value === 'string') {
        return true;
      }
      return false;
    })
})

const addReviewSchema = yup.object().shape({
  image: yup
    .mixed()
    .required("Image is required").test("validFileOrUrl", "Unsupported file format or invalid URL", (value) => {
      if (value instanceof File) {
        const supportedFormats = ["image/jpeg", "image/png", "image/gif"];
        return supportedFormats.includes(value.type) && value.size <= 5 * 1024 * 1024;
      } else if (typeof value === 'string') {
        return true;
      }
      return false;
    }),
  userName: yup.string().required('User Name is required'),
  rating: yup.number().required('Rating is Required'),
  review: yup.string().required('Review is required').max(3000, "Review exceed 3000 characters")
})

const addLocationTypeSchema = yup.object().shape({
  title: yup.string().required("Title is required")
})

export {
  LoginVS,
  RegisterVS,
  accountInfoValidationSchema,
  passwordValidationSchema,
  addAmenitySchema,
  addReviewSchema,
  addLocationTypeSchema
};
