import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./layout";
import Auth from "pages/auth";

import { adminRoutes, publicRoutes, rolesRoutes } from "./allRoutes";

const AuthRoute = () => {
  const user = useSelector((state: any) => state.root.userSlice);
  return (
    <>
      <Routes>
        {!user?.isLoggedIn
          ? publicRoutes.map((route, inx) => {
              return (
                <React.Fragment key={inx}>
                  <Route path={route.path} key={inx} element={<Auth />} />
                  <Route path={"*"} element={<Navigate to="/" replace />} />
                </React.Fragment>
              );
            })
          : adminRoutes.map((route, inx) => {
              return (
                <React.Fragment key={inx}>
                  <Route
                    path={route?.path}
                    key={inx}
                    element={<Layout {...route} />}
                  />
                  <Route path={"*"} element={<Navigate to="/" replace />} />
                </React.Fragment>
              );
            })}
      </Routes>
    </>
  );
};

export default AuthRoute;
