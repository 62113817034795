import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icons, Images } from "assets";

import StatsCard from "shared/components/statsCard";
import CustomTableHeader from "shared/components/customTableHeader";
import UserConfirmation from "shared/modals/userConfimation";
import GuiderTableSkeleton from "shared/skeletons/guidersTableSkeleton";
import CustomTooltip from "shared/components/toolTip";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";

import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { columnHeadings, sortingOptions } from "./constants";
import useDebounce from "shared/customHooks/useDebounce";

import {
  getAllUsers,
  getTotalStats,
  changeUserStats,
} from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";

import styles from "./style.module.scss";

const Guiders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardStats, setDashboardStats] = useState<any>([
    {
      id: 1,
      title: "Total Users",
      value: null,
      icon: <Icons.UserIcon />,
      bg_color: "#F2F7FB",
    },
    {
      id: 2,
      title: "Active Users",
      value: null,
      icon: <Icons.UserIcon />,
      bg_color: "#E5FEF2",
    },
    {
      id: 3,
      title: "Blocked Users",
      value: null,
      icon: <Icons.GuidersIcon />,
      bg_color: "#FFF2ED",
    },
  ]);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(true);
  const [statsLoader, setStatsLoader] = useState<boolean>(true);
  const [guiders, setGuiders] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [openActivateModal, setOpenActivateModal] = useState<boolean>(false);
  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [confirmAction, setConfirmAction] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [sort, setSort] = useState<number | string>("");

  const handleDeactivateUser = (item: any) => {
    setModalType("Deactivate");
    setUserId(item?._id);
    setOpenDeactivateModal(true);
  };

  const handleActivateUser = (item: any) => {
    setModalType("Activate");
    setUserId(item?._id);
    setOpenActivateModal(true);
  };

  const handleFeatured = (user: any) => {
    setLoader(true);
    changeUserStats(user?._id)
      .then(({ status, data }) => {
        if (status) {
          toastMessage("success", data);
          getUsers();
        }
      })
      .catch((err) => {
        console.log("Error------", err);
        toastMessage("error", "Something went wrong!");
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Guiders / Outfitters",
            action: () => {
              navigate(routeConstant.guiders.path);
            },
          },
        ],
      })
    );
  }, []);

  const getUsers = () => {
    setLoader(true);
    getAllUsers({
      search: searchText,
      activePage,
      itemsPerPage: 10,
      sort: sort?.toString() || "",
    })
      .then(({ data, status }) => {
        if (status) {
          const { count, users } = data;
          setGuiders(users);
          setConfirmAction(false);
          setTotalPages(count);
        }
      })
      .catch((err: any) => {
        console.log("Error-----", err);
        toastMessage("err", "Something went wrong!");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getStats = () => {
    setStatsLoader(true);
    getTotalStats()
      .then(({ status, data }) => {
        if (status) {
          const { users, locations, activeUsers, blockedUsers } = data;
          setDashboardStats((prevState: any) => {
            return prevState?.map((item: any) => {
              if (item?.id === 1) {
                return { ...item, value: users };
              } else if (item?.id === 2) {
                return { ...item, value: activeUsers };
              } else if (item?.id === 3) {
                return { ...item, value: blockedUsers };
              }
            });
          });
        }
      })
      .finally(() => {
        setStatsLoader(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, [sort, searchText, activePage, confirmAction]);

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3 mb-8">
        {/* Stats Card */}
        {dashboardStats?.map((stat: any) => {
          return (
            <React.Fragment key={stat?.id}>
              <StatsCard loader={statsLoader} stats={stat} />
            </React.Fragment>
          );
        })}
      </div>

      {/* Guider Custom Header */}
      <CustomTableHeader
        sortText={sort}
        showFilter={true}
        heading=" All Guider's Profiles"
        subHeading="Showing the list of Guiders/Outfitters"
        setSearch={setSearch}
        search={search}
        sortingOptions={sortingOptions}
        showModalButton={false}
        setSort={setSort}
        setActivePage={setActivePage}
      />

      {/* Guiders Table */}
      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            {guiders?.length > 0 && (
              <thead className="text-xs text-gray-700 capitalize bg-gray-50  ">
                <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                  {columnHeadings.map((column: any) => {
                    return (
                      <th
                        key={column.id}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-GRAY"
                      >
                        {column.value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {loader ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <GuiderTableSkeleton key={index} actions={3} />
                ))
              ) : (
                <>
                  {guiders?.length > 0
                    ? guiders?.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="w-8 h-8 rounded-lg">
                                  <img
                                    alt={item?.firstname}
                                    loading="lazy"
                                    className="w-full h-full rounded-full object-cover aspect-[1]"
                                    src={
                                      item?.profile_picture_full_path
                                        ? item?.profile_picture_full_path
                                        : Images.BlankProfile
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.firstname || item?.lastname
                                    ? `${item?.firstname} ${item?.lastname}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.email ? item?.email : "Not Provided"}
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row items-center gap-2">
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.phone ? item?.phone : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row items-center gap-2">
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.locationCount
                                    ? item?.locationCount
                                    : 0}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.status ? "Active" : "Blocked"}
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <Link
                                  to={routeConstant.guide_detail.path.replace(
                                    ":id",
                                    item?._id
                                  )}
                                  target="_blank"
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"View Profile"}
                                    icon={<Icons.ViewIcon />}
                                    index={1}
                                  />
                                </Link>
                                {item?.status ? (
                                  <span
                                    className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                    onClick={() => handleDeactivateUser(item)}
                                  >
                                    <CustomTooltip
                                      content={"Deactivate Account"}
                                      icon={<Icons.DeactivateUserIcon />}
                                      index={3}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                    onClick={() => handleActivateUser(item)}
                                  >
                                    <CustomTooltip
                                      content={"Activate Account"}
                                      icon={<Icons.Activate />}
                                      index={2}
                                    />
                                  </span>
                                )}
                                {item?.isSponsored ? (
                                  <span
                                    className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                    onClick={() => handleFeatured(item)}
                                  >
                                    <CustomTooltip
                                      content={"Remove Featured"}
                                      icon={<Icons.RemoveFeatured />}
                                      index={4}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                    onClick={() => handleFeatured(item)}
                                  >
                                    <CustomTooltip
                                      content={"Mark as Featured"}
                                      icon={<Icons.Featured />}
                                      index={5}
                                    />
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* NO Data Found */}
        {guiders?.length === 0 && !loader && (
          <NoDataFound icon={<Icons.NoGuider />} />
        )}
        {/* Pagination */}
        {guiders?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>

      {/* Confirm Activate User */}
      <UserConfirmation
        type={modalType}
        userId={userId}
        text={
          modalType === "Activate"
            ? "Are you sure you want to activate this user?"
            : "Are you sure you want to deactivate this user?"
        }
        viewModal={
          modalType === "Activate" ? openActivateModal : openDeactivateModal
        }
        setCloseModal={
          modalType === "Activate"
            ? () => {
                setOpenActivateModal(false);
              }
            : () => {
                setOpenDeactivateModal(false);
              }
        }
        setConfirmAction={() => setConfirmAction(true)}
        subText={
          modalType === "Activate"
            ? "Clicking the confirm button will activate the selected user"
            : "Clicking the confirm button will deactivate the selected user"
        }
      />

      {/* Confirm Deactivate Modal */}
      {/* <UserConfirmation
        type="Deactivate"
        userId={userId}
        text={"Are you sure you want to deactivate this user?"}
        viewModal={openDeactivateModal}
        setCloseModal={() => {
          setOpenDeactivateModal(false);
        }}
        setConfirmAction={() => setConfirmAction(true)}
      /> */}
    </div>
  );
};

export default Guiders;
