import React, { useEffect, useState } from "react";
import CustomTableHeader from "shared/components/customTableHeader";

import { columnHeadings, sortingOptions } from "./constants";
import styles from "./style.module.scss";
import NODataFound from "shared/components/noDataFound";
import { Icons, Images } from "assets";
import Pagination from "shared/components/pagination";
import { reportedUsers } from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import ReportDetailModal from "shared/modals/reportDetailModal";
import CustomTooltip from "shared/components/toolTip";
import ReportsTableSkeleton from "shared/skeletons/reportsSkeleton";
import useDebounce from "shared/customHooks/useDebounce";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routeConstant } from "shared/routes/routesConstant";

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [reports, setReports] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [openViewReportModal, setOpenViewReportModal] =
    useState<boolean>(false);
  const [report, setReport] = useState<any>(null);

  const getReports = () => {
    setLoader(true);
    reportedUsers({
      status: sort,
      search: searchText,
      activePage: activePage,
      itemsPerPage: 10,
    })
      .then(({ data, status }) => {
        if (status) {
          const { reports, count } = data;
          setLoader(false);
          setReports(reports);
          setTotalPages(count);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error----", err);
        toastMessage("error", "Something went wrong!");
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Reports",
            action: () => {
              navigate(routeConstant.reports.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getReports();
  }, [sort, searchText, activePage]);

  return (
    <section>
      <div>
        <CustomTableHeader
          heading="All Reports"
          subHeading="Showing the list of all reports"
          showFilter={true}
          setSearch={setSearch}
          search={search}
          setSort={setSort}
          sortingOptions={sortingOptions}
          sortText={sort}
          showModalButton={false}
          setActivePage={setActivePage}
        />
      </div>

      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            {reports.length > 0 && (
              <thead className="text-xs text-gray-700 capitalize bg-gray-50  ">
                <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                  {columnHeadings.map((column: any) => {
                    return (
                      <th
                        key={column.id}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-GRAY"
                      >
                        {column.value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {loader ? (
                Array.from({ length: 8 }).map((_, index) => {
                  return <ReportsTableSkeleton key={index} actions={1} />;
                })
              ) : (
                <>
                  {reports.length > 0
                    ? reports.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="w-8 h-8 rounded-lg">
                                  <img
                                    alt={item?.reportedUser?.firstname}
                                    loading="lazy"
                                    className="w-full h-full rounded-full object-cover aspect-[1]"
                                    src={
                                      item?.reportedUser
                                        ?.profile_picture_full_path
                                        ? item?.reportedUser
                                            ?.profile_picture_full_path
                                        : Images.BlankUserPlaceHolder
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.reportedUser?.firstname ||
                                  item?.reportedUser?.lastname
                                    ? `${item?.reportedUser?.firstname} ${item?.reportedUser?.lastname}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.reportedUser?.addedLocationsCount
                                  ? item?.reportedUser?.addedLocationsCount
                                  : "Not Provided"}
                              </div>
                            </td>
                            <td scope="row" className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="w-8 h-8 rounded-lg">
                                  <img
                                    alt={item?.createdBy?.firstname}
                                    loading="lazy"
                                    className="w-full h-full rounded-full object-cover aspect-[1]"
                                    src={
                                      item?.createdBy?.profile_picture_full_path
                                        ? item?.createdBy
                                            ?.profile_picture_full_path
                                        : Images.BlankUserPlaceHolder
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.createdBy?.firstname ||
                                  item?.createdBy?.lastname
                                    ? `${item?.createdBy?.firstname} ${item?.createdBy?.lastname}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-72">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.reason
                                  ? `${item?.reason.slice(0, 50)}...`
                                  : "Not Provided"}
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                <span
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                  onClick={() => {
                                    setReport(item);
                                    setOpenViewReportModal(true);
                                  }}
                                >
                                  <CustomTooltip
                                    content={"View"}
                                    icon={<Icons.ViewIcon />}
                                    index={1}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {reports.length === 0 && !loader && (
          <NODataFound
            icon={<Icons.NoAmenity />}
            text="No Reports Found."
            detail={"There is no report avaialble right now."}
          />
        )}
        {reports?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>
      {/* Report Detail Modal */}
      <ReportDetailModal
        isOpen={openViewReportModal}
        close={() => setOpenViewReportModal(false)}
        report={report}
      />
    </section>
  );
};

export default Reports;
