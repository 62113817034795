import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="text-sm max-w-7xl mx-auto px-5 flex flex-col gap-4 items-center py-8  font-normal text-BLACK md:flex-row md:justify-between md:text-sm lg:text-base">
      <div>© 2023 KC & E. All rights reserved.</div>
      <div>
        <ul className="flex gap-5">
          <li>
            <Link to="/">Map</Link>
          </li>
          <li>
            <Link to="/">Favourite</Link>
          </li>
          <li>
            <Link to="/">Profile</Link>
          </li>
        </ul>
      </div>
      <div>
        <ul className="flex gap-5">
          <li>
            <Link to="/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/">Terms And Condition</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
