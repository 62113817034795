import React, { useEffect, useState } from "react";

import { Icons } from "assets";
import { Images } from "assets";
import Spinner from "../spinner";

import { activateUser, deactivateUser } from "shared/services/adminService";
import { toastMessage } from "../toast";

const GuiderDetailsCard = ({ loader, userDetail, setUserDetail }: any) => {
  // Loading State for Activation and Deactivation
  const [loading, setLoading] = useState<boolean>(false);
  const { user, addedLocations } = userDetail;

  const handleSuspendAccount = () => {
    setLoading(true);
    const service = user?.status ? deactivateUser : activateUser;
    service(user?._id)
      .then(({ status, data }) => {
        if (status) {
          let temp = { ...userDetail };
          temp.user.status = !temp.user.status;
          setUserDetail(temp);
          setLoading(false);
          toastMessage(
            user?.status ? "success" : "err",
            user?.status ? "User is Activated" : "User is Deactivated"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage("err", "Something went wrong!");
        setLoading(false);
      });
  };

  return (
    <div className="relative p-4 pb-6 border bg-white border-LOCATION_CARD_BORDER rounded-lg shadow-lg">
      <div className="hidden absolute w-10 h-10 top-[14px] left-[14px] bg-ICON_BACKGROUND_GRAY p-[10px] rounded-lg">
        <Icons.FlagIcon />
      </div>
      <div className="flex flex-col items-center">
        {loader ? (
          <div role="status" className="animate-pulse">
            <div className="mb-2 w-24 h-24 rounded-full bg-gray-200"></div>
          </div>
        ) : (
          <div className=" mb-[10px] w-[72px] h-[72px] md:w-[96px] md:h-[96px] mx-aut rounded-full">
            <img
              src={
                user?.profile_picture_full_path
                  ? user?.profile_picture_full_path
                  : Images.BlankProfile
              }
              className="object-cover w-full h-full rounded-full"
              alt="image-guider"
            />
          </div>
        )}
        <h3 className="text-base mb-2 leading-6 text-center font-medium text-BLACK md:text-xl">
          {loader ? (
            <div
              role="status"
              className=" flex justify-center max-w-sm animate-pulse"
            >
              <div className="h-3  bg-gray-200 rounded-full w-32 mb-2"></div>
            </div>
          ) : (
            `${user?.firstname ? user?.firstname : ""} ${
              user?.lastname ? user?.lastname : ""
            }`
          )}
        </h3>
        <h4 className="text-sm md:text-base leading-5 text-GRAY font-normal">
          {loader ? (
            <div
              role="status"
              className="flex justify-center max-w-sm animate-pulse"
            >
              <div className="h-3  bg-gray-200 rounded-full w-32 mb-2"></div>
            </div>
          ) : (
            "Guiders / Outfitters"
          )}
        </h4>
        <div className=" mt-4 py-2 text-center border border-LOCATION_CARD_BORDER rounded-lg self-stretch">
          <p className="text-base mb-[7px] leading-6 md:text-xl font-medium text-BLACK">
            {loader ? (
              <div
                role="status"
                className=" flex justify-center max-w-sm animate-pulse"
              >
                <div className="h-2.5  bg-gray-200 rounded-full w-16 mb-2"></div>
              </div>
            ) : (
              `${addedLocations ? addedLocations : "0"}`
            )}
          </p>
          <p className="text-sm leading-5 md:text-base font-normal text-GRAY">
            {loader ? (
              <div
                role="status"
                className=" flex justify-center max-w-sm animate-pulse"
              >
                <div className="h-2.5  bg-gray-200 rounded-full w-32 mb-8"></div>
              </div>
            ) : (
              "Locations"
            )}
          </p>
        </div>
        <button
          className="mt-4 p-3 text-xs font-medium leading-5 md:text-sm text-center rounded-lg bg-PRIMARY text-white self-stretch transition-all delay-150 hover:opacity-80"
          onClick={handleSuspendAccount}
        >
          {loader || loading ? (
            <Spinner />
          ) : user?.status ? (
            "Deactivate Account"
          ) : (
            "Activate Account"
          )}
        </button>
      </div>
    </div>
  );
};

export default GuiderDetailsCard;
