import React from "react";

const ReviewsTableSkeleton = ({ actions }: any) => {
  return (
    <tr className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER">
      <td className="px-6 py-4">
        <span className="flex gap-2 items-center animate-pulse">
          <svg
            className="w-8 h-8 text-gray-200 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div>
            <div className="h-2.5 bg-gray-200 rounded-sm  w-20 "></div>
          </div>
        </span>
      </td>
      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm w-36"></span>
        </span>
      </td>

      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm  w-36"></span>
        </span>
      </td>
      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm  w-36"></span>
        </span>
      </td>
      <td className="px-6 py-4">
        <span className="flex gap-2 animate-pulse">
          {Array.from({ length: actions }).map((i, index: any) => {
            return (
              <span
                key={index}
                className="block h-6 bg-gray-200   w-6 rounded-full"
              ></span>
            );
          })}
        </span>
      </td>
    </tr>
  );
};

export default ReviewsTableSkeleton;
