import React from "react";
import styles from "./style.module.scss";

const Rating = (props: any) => {
  let limit = props.limit || 5;
  const [initialValue, setInitialValue] = React.useState(
    props.currentValue || 0
  );
  const handleClick = (event: any) => {
    props.setRating(Number(+event.target.getAttribute("data")));
    setInitialValue(+event.target.getAttribute("data"));
    if (props.callback) {
      props.callback(+event.target.getAttribute("data"));
    }
  };

  const handleDoubleClick = (event: any) => {
    if (+event.target.getAttribute("data") === 1) {
      setInitialValue(0);
      if (props.callback) {
        props.callback(+event.target.getAttribute("data"));
      }
    }
  };
  return (
    <div
      className="flex gap-1"
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {[...new Array(limit).keys()].map((param) => (
        <span
          key={param}
          data={param + 1}
          className={
            param < initialValue
              ? `${styles.star} ${styles.rated}`
              : styles.star
          }
        />
      ))}
    </div>
  );
};

export default Rating;
