import React from "react";

import { Icons } from "assets";

// import styles from "./styles.module.scss";

interface PaginationProps {
  active: number;
  setActive: any;
  totalPages: number;
}
const Pagination = ({ active, setActive, totalPages }: PaginationProps) => {
  const handleClick = (index: number) => {
    setActive(index);
  };
  const next = () => {
    if (active === totalPages) return;

    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;

    setActive(active - 1);
  };
  return (
    <div className="flex justify-center items-center gap-4 px-6 py-4 md:justify-between border-t border-LOCATION_CARD_BORDER">
      <button
        className="hidden text-BLACK font-medium text-sm items-center gap-2 rounded-lg border md:flex border-LIGHT_GRAY2 p-2 hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
        onClick={prev}
        disabled={active === 1}
      >
        <span>
          <Icons.PreviousIcon />
        </span>
        <span>Previous</span>
      </button>
      <div className="flex flex-wrap justify-center items-center gap-2 md:flex-nowrap">
        {Array.from({ length: totalPages }).map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => handleClick(index + 1)}
              className={`p-3 w-10 rounded-lg text-sm font-medium text-center hover:cursor-pointer ${
                active === index + 1 ? "text-BLACK bg-BLUE_1" : "text-GRAY"
              }`}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
      <button
        className="hidden text-BLACK font-medium text-sm items-center gap-2 rounded-lg border border-LIGHT_GRAY2 p-2 md:flex hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
        onClick={next}
        disabled={active === totalPages}
      >
        <span>Next</span>
        <span>
          <Icons.NextIcon />
        </span>
      </button>
    </div>
  );
};

export default Pagination;
