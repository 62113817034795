import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useFormik } from "formik";

import { addAmenitySchema } from "shared/utils/validation";
import { Icons } from "assets";
import { editAmenity } from "shared/services/adminService";
import Spinner from "shared/components/spinner";
import { toastMessage } from "shared/components/toast";
import styles from "./style.module.scss";

interface EditAmenityModal {
  isOpen: boolean;
  close: any;
  selectedAmenity: any;
}

const EditAmenityModal = ({
  isOpen,
  close,
  selectedAmenity,
}: EditAmenityModal) => {
  const initialValues = {
    title: selectedAmenity?.name,
    image: selectedAmenity?.avatar,
  };

  const [file, setFile] = useState<{ preview: string; raw: File | null }>({
    preview: "",
    raw: null,
  });

  const handleEditAmenity = () => {
    formik.setSubmitting(true);
    const payload: any = {
      name: formik.values.title,
    };
    // Create FormData
    const formBody = new FormData();
    Object.keys(payload).forEach((key) => {
      formBody.append(key, payload[key]);
    });

    if (file.raw) {
      formBody.append("profile_picture", formik?.values?.image); // Append the image file
    }

    editAmenity(formBody, selectedAmenity?._id)
      .then(({ status, data }) => {
        if (status) {
          formik.setSubmitting(false);
          toastMessage("success", "Amenity Edited Successfully");
          close();
        }
      })
      .catch((err) => {
        console.log("Err---", err);
        toastMessage("error", "Something went wrong!");
      });
  };

  const formik = useFormik({
    validationSchema: addAmenitySchema,
    initialValues: initialValues,
    onSubmit: () => handleEditAmenity(),
  });
  const handleClick = () => {
    document?.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        formik.setFieldValue("image", e.target.files && e.target.files[0]);
        setFile({
          preview: e.target.result as string,
          raw: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    formik.setFieldValue("title", selectedAmenity?.name);
    formik.setFieldValue("image", selectedAmenity?.avatar);
    setFile({
      preview: selectedAmenity?.avatar,
      raw: null,
    });
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => {
          close();
          formik.setFieldValue("image", null);
          formik.setFieldValue("title", null);
        }}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}

        <div className="fixed inset-0 flex w-screen items-center justify-center py-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-[90vw] w-[500px]  pb-4 rounded-md bg-white md:max-w-lg">
              <Dialog.Title className="relative font-bold text-BLACK leading-4 text-sm  p-4">
                <span className="block font-neueMedium text-center text-sm font-semibold leading-5 text-BLACK">
                  Edit Amenity
                </span>
                <span
                  onClick={close}
                  className={`${styles.iconWrapper} absolute top-[14px] right-[13px] cursor-pointer`}
                >
                  <Icons.Cross />
                </span>
              </Dialog.Title>
              <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
              <Dialog.Description className="mt-8">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="mx-auto px-4"
                >
                  <div className="grid grid-cols-1 gap-[6px] mb-5">
                    <label
                      className=" text-xs font-normal leading-4 text-BLACK md:text-sm"
                      htmlFor="title"
                    >
                      Amenity Title
                    </label>
                    <input
                      value={
                        formik.values.title
                          ? formik?.values?.title
                          : selectedAmenity?.name
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="title"
                      className=" text-xs p-4 border border-LOCATION_CARD_BORDER rounded-lg focus:outline-none md:text-sm"
                      placeholder="Enter Amenity Name"
                      id="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <span className="text-xs text-red-500 font-medium md:text-sm">
                        {formik?.errors?.title}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-1 gap-[14px]">
                    <label
                      className=" text-xs font-normal leading-4 text-BLACK md:text-sm"
                      htmlFor="amenity-image"
                    >
                      Upload Image
                    </label>
                    <div
                      onClick={handleClick}
                      className=" h-36 border-dashed border border-LOCATION_CARD_BORDER rounded-lg grid place-items-center cursor-pointer"
                    >
                      <p className="font-normal text-xs leading-4 md:text-sm max-w-32 text-center">
                        <span className=" text-SECONDARY font-medium">
                          Upload photo
                        </span>{" "}
                        or just drag and drop
                        <input className="hidden focus:outline-none"></input>
                      </p>
                      <input
                        key={file ? file.preview : "fileInput"}
                        id="fileInput"
                        name="image"
                        onBlur={formik.handleBlur}
                        type="file"
                        onChange={(e: any) => handleFileChange(e)}
                        style={{ display: "none" }}
                      />
                    </div>
                    {formik.errors.image && formik.touched.image && (
                      <span className="text-xs text-red-500 font-medium md:text-sm">
                        {formik?.errors?.image}
                      </span>
                    )}
                    {file?.preview && (
                      <div className="w-20 h-20 relative border border-LOCATION_CARD_BORDER rounded-lg">
                        <span
                          onClick={() => {
                            formik.setFieldValue("image", null);
                            setFile({
                              preview: "",
                              raw: null,
                            });
                          }}
                          className="absolute right-0 top-[0px] cursor-pointer p-1"
                        >
                          <Icons.Cross />
                        </span>
                        <img
                          className="w-full h-full object-cover block rounded-lg"
                          src={file?.preview && file?.preview}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => formik.handleSubmit()}
                    type="submit"
                    className=" mt-4 rounded-lg w-full text-center border-none py-4 bg-PRIMARY font-medium leading-5 text-white text-sm md:text-base hover:opacity-80 transition-all ease-linear duration-100"
                  >
                    {formik.isSubmitting ? <Spinner /> : "Update"}
                  </button>
                </form>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditAmenityModal;
