import React, { memo, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import classNames from "classnames";

import styles from "./style.module.scss";
import { GoogleKey } from "shared/utils/endPoints";
import { Icons } from "assets";

interface MapsComponentProps {
  containerStyle?: any;
  data: any;
}

const center = {
  lat: 64.2008,
  lng: -149.4937,
};

const mapOptions = {
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ],
  keyboardShortcuts: false,
};

const MapComponent = ({ containerStyle, data }: MapsComponentProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleKey,
  });
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(data?.formatted_address);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleMarkerHover = () => {
    setSelectedPlace(data?.address);
    setIsHovered(true);
  };

  const handleMarkerLeave = () => {
    setIsHovered(false);
    setSelectedPlace("");
  };

  return isLoaded ? (
    <GoogleMap
      center={data?.formatted_address}
      zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerClassName={classNames(
        containerStyle ? containerStyle : styles.containerStyle
      )}
      options={mapOptions}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        <Marker
          onMouseOver={handleMarkerHover}
          // onMouseOut={handleMarkerLeave}
          onClick={() => {
            setSelectedPlace(data?.address);
            const url = `https://www.google.com/maps/search/?api=1&query=${data?.formatted_address.lat},${data?.formatted_address.lng}`;
            window.open(url, "_blank");
            handleMarkerLeave();
          }}
          zIndex={15}
          position={data?.formatted_address ? data.formatted_address : {}}
          icon={{
            url: data?.type === "Fishing" ? "/fish-pin.png" : "/hunt-pin.png",
            scaledSize: new window.google.maps.Size(40, 44),
          }}
          // onClick={() => {
          //   handleShowPinDetail(itm);
          // }}
          // key={inx}
        >
          {isHovered && selectedPlace && (
            <InfoWindow
              zIndex={99999999999999999999}
              onCloseClick={() => setSelectedPlace(null)}
            >
              <div className="flex gap-2 items-start p-2 relative">
                <span>
                  <Icons.Address />
                </span>
                <div className="flex gap-2 flex-col">
                  <div className=" text-[10px]  font-medium">
                    {selectedPlace}
                  </div>
                  <div className="text-[9px] flex items-center text-GRAY">
                    Click the marker to open location on the map
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      </>
    </GoogleMap>
  ) : (
    <div
      className={classNames(
        containerStyle ? containerStyle : styles.containerStyle
      )}
    ></div>
  );
};

export default MapComponent;
