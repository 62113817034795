import React from "react";

import { Tab } from "@headlessui/react";

const CustomTabs = ({ tabs }: any) => {
  return (
    <Tab.List className="border-b border-LOCATION_CARD_BORDER mb-11 flex gap-8">
      {tabs.map((tab: any) => {
        return (
          <Tab
            key={tab.id}
            className="focus-visible:border-none focus-visible:outline-none"
          >
            {({ selected }: any) => (
              <span
                className={
                  selected
                    ? " whitespace-pre border-b border-SECONDARY pb-3  text-SECONDARY text-[15px] font-medium block mb-[-1px]"
                    : " whitespace-pre bg-white text-GRAY font-normal pb-3 text-[15px] block"
                }
              >
                {tab.title}
              </span>
            )}
          </Tab>
        );
      })}
    </Tab.List>
  );
};

export default CustomTabs;
