import React, { useEffect, useState } from "react";
import CustomTableHeader from "shared/components/customTableHeader";

import AddNewAmenityModal from "shared/modals/addNewAmenityModal";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { sortingOptions } from "./constants";
import AmenityCard from "shared/components/amenityCard";
import useDebounce from "shared/customHooks/useDebounce";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { getAmenities, deleteAmenity } from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import GeneralSkeleton from "shared/skeletons/generalSkeleton";
import Confirmation from "shared/modals/confirmation";
import NODataFound from "shared/components/noDataFound";
import { Icons } from "assets";
import EditAmenityModal from "shared/modals/editAmenityModal";

const Amenities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [filter, setFilter] = useState<number>(sortingOptions[0].value);
  const [addAmenitiy, setAddAmenity] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedAmenity, setSelectedAmenity] = useState<any>(null);
  const [reloadAmenities, setReloadAmenities] = useState<boolean>(false);
  const [editAmenity, setEditAmenity] = useState<boolean>(false);

  const getAllAmenities = () => {
    setLoader(true);
    getAmenities({ search: searchText, filter: filter })
      .then(({ status, data }) => {
        if (status) {
          setLoader(false);
          setAmenities(data);
          setOpenDeleteModal(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toastMessage("err", "Failed to Load requested locations");
        console.log("Error-----", err);
      });
  };

  const handleDelete = () => {
    setLoader(true);
    deleteAmenity(selectedAmenity?._id)
      .then(({ data, status }) => {
        if (status) {
          setLoader(false);
          getAllAmenities();
          toastMessage("success", data);
        }
      })
      .catch((err) => {
        toastMessage("err", "Something went wrong!");
        console.log("Error----", err);
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Amenities",
            action: () => {
              navigate(routeConstant.amenities.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getAllAmenities();
  }, [searchText, filter, reloadAmenities]);
  return (
    <section>
      <CustomTableHeader
        modalButtonText="Add New Amenity"
        search={search}
        setSort={setFilter}
        sortText={filter}
        sortingOptions={sortingOptions}
        setSearch={setSearch}
        setActivePage={setActivePage}
        showFilter={true}
        heading="All Amenities"
        subHeading="Showing all amenities"
        showModalButton={true}
        setOpenModal={setAddAmenity}
      />

      <div className=" border-t border-t-LOCATION_CARD_BORDER pt-6">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {loader
            ? Array.from({ length: 6 }).map((_, index) => {
                return (
                  <div
                    key={index}
                    className="border border-LOCATION_CARD_BORDER p-3 rounded-2xl"
                  >
                    <GeneralSkeleton className="w-24 h-24 bg-gray-200 rounded-lg mx-auto" />
                    <GeneralSkeleton className="w-28 h-3 bg-gray-200 mx-auto mt-4" />
                    <GeneralSkeleton className=" w-full h-px bg-gray-200 mt-4" />
                    <div className="flex justify-center gap-4 mt-8">
                      <GeneralSkeleton className="w-28 h-4 lg:w-16 bg-gray-200" />
                      <GeneralSkeleton className="w-28 h-4 lg:w-16 bg-gray-200" />
                    </div>
                  </div>
                );
              })
            : amenities.map((amenity: any) => {
                return (
                  <AmenityCard
                    openEditAmenity={setEditAmenity}
                    openDeleteModal={setOpenDeleteModal}
                    setSelectedAmenity={setSelectedAmenity}
                    key={amenity?._id}
                    amenity={amenity}
                  />
                );
              })}
        </div>
      </div>

      {/*  Add Amenity Modal */}
      <AddNewAmenityModal
        isOpen={addAmenitiy}
        close={() => {
          setReloadAmenities((prevState) => !prevState);
          setAddAmenity(false);
        }}
      />

      {/* Edit Amenity Modal */}
      <EditAmenityModal
        selectedAmenity={selectedAmenity}
        isOpen={editAmenity}
        close={() => {
          setReloadAmenities((prevState) => !prevState);
          setEditAmenity(false);
        }}
      />

      {/* Delete Confirmation */}
      <Confirmation
        isOpen={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        text="Are you sure you want yo delete this amenity?"
        handleConfirm={handleDelete}
        loader={loader}
        subText=" Clicking the confirm button will permanently delete the
        selected amenity."
      />

      {/* NO Amenities Added */}
      {amenities?.length <= 0 && (
        <NODataFound
          icon={<Icons.NoAmenity />}
          text="No Amenities Found."
          detail="There is no amenities available. Add them from here"
        />
      )}
    </section>
  );
};

export default Amenities;
