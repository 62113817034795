import Auth from "pages/auth";
import { routeConstant } from "./routesConstant";

import DashboardAdmin from "pages/dashboardAdmin";
import Guiders from "pages/guiders";
import Locations from "pages/locations";
import LocationRequests from "pages/locationRequests";
import Settings from "pages/settings";
import GuideDetail from "pages/guideDetail";
import PlaceDetail from "pages/placeDetail";
import Amenities from "pages/amenities";
import Reports from "pages/reports";
import Reviews from "pages/reviews";
import ServiceTypes from "pages/serviceTypes";
import SupportTicket from "pages/supportTickets";
import TermsAndConditions from "pages/termsAndConditions";
import PrivacyPolicy from "pages/privacyPolicy";

const publicRoutes = [{
    path: routeConstant.auth.path,
    title: routeConstant.auth.title,
    Component: Auth,
}]

const commonRoutes: any = [{
    path: routeConstant.settings.path,
    title: routeConstant.settings.title,
    Component: Settings
}];

const adminRoutes = [
    ...commonRoutes,
    {
        path: routeConstant.dashboardAdmin.path,
        title: 'Dashboard',
        Component: DashboardAdmin
    },
    {
        path: routeConstant.guiders.path,
        title: routeConstant.guiders.title,
        Component: Guiders
    },
    {
        path: routeConstant.locations.path,
        title: routeConstant.locations.title,
        Component: Locations
    },
    {
        path: routeConstant.location_requests.path,
        title: routeConstant.location_requests.title,
        Component: LocationRequests
    },
    {
        path: routeConstant.guide_detail.path,
        title: routeConstant.guide_detail.title,
        Component: GuideDetail
    },
    {
        path: routeConstant.place_details.path,
        title: routeConstant.place_details.title,
        Component: PlaceDetail
    },
    {
        path: routeConstant.amenities.path,
        title: routeConstant.amenities.title,
        Component: Amenities
    },
    {
        path: routeConstant?.reports?.path,
        title: routeConstant.reports.title,
        Component: Reports
    },
    {
        path: routeConstant?.reviews.path,
        title: routeConstant?.reviews.title,
        Component: Reviews
    },
    {
        path: routeConstant.serviceTypes.path,
        title: routeConstant.serviceTypes.title,
        Component: ServiceTypes
    },
    {
        path: routeConstant.getInTouch.path,
        title: routeConstant.getInTouch.title,
        Component: SupportTicket
    },
    {
        path: routeConstant.termsAndConditions.path,
        title: routeConstant.termsAndConditions.title,
        Component: TermsAndConditions
    },
    {
        path: routeConstant.privacyPolicy.path,
        title: routeConstant.privacyPolicy.title,
        Component: PrivacyPolicy
    }
];


const rolesRoutes = [{
    role_id: 1,
    title: 'Admin',
    routes: adminRoutes
}]


export { publicRoutes, adminRoutes, rolesRoutes }