import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useWindowSize from "shared/customHooks/windowSize";

interface ChartCompProps {
  graphData: any[];
}

const ChartComp = ({ graphData }: ChartCompProps) => {
  const width = useWindowSize();
  return (
    <section className="border border-LOCATION_CARD_BORDER py-6 px-5 rounded-2xl">
      <div className="flex flex-col gap-2 mb-4 md:mb-9 md:flex-row md:justify-between md:items-center">
        <h1 className=" text-sm leading-5 font-medium text-BLACK md:text-base">
          User Joining Figure
        </h1>
        <div className="flex justify-start items-center gap-7">
          <p className="flex text-xs items-center gap-2 md:text-sm">
            <span className=" block w-[10px] h-[10px] rounded-full bg-SECONDARY"></span>
            <span className="text-BLACK font-normal leading-6 tracking-[.1px]">
              Users
            </span>
          </p>
        </div>
      </div>
      <div>
        <ResponsiveContainer width="100%" height={width < 1500 ? 340 : 400}>
          <LineChart height={300} data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={{ fill: "#ADB5BD" }} dataKey="name" />
            <YAxis tick={{ fill: "#ADB5BD" }} dataKey="value" />
            <Tooltip />

            <Line type="monotone" dataKey="value" stroke="#dc6d43" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default ChartComp;
