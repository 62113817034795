import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import HeaderDropDown from "../headerDropDownMenu";

import { routeConstant } from "shared/routes/routesConstant";
import { Icons } from "assets";
import SearchComponent from "../globalSearch";

const SecondaryHeader = () => {
  const navigate = useNavigate();
  const menuItems = [
    {
      menuText: "Account Settings",
      link: routeConstant.settings.path,
      action: () => navigate(routeConstant.settings.path),
    },
  ];
  return (
    <div className="md:h-[70px] max-w-7xl px-5 mx-auto py-3 ">
      <div className="flex justify-between items-center">
        <div>
          <Link to="/">
            <Icons.Logo />
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <div className="hidden md:block">
            <SearchComponent />
          </div>
          <div className="flex gap-[10px] items-center  pb-0 hover:cursor-pointer ">
            {/* Dropdown Menu Headless UI*/}
            <HeaderDropDown menuItems={menuItems} />
            {/* End drop down */}
          </div>
        </div>
      </div>
      <div className="block mt-4 md:hidden">
        <SearchComponent />
      </div>
    </div>
  );
};

export default SecondaryHeader;
