import { Icons } from "assets";

const columnHeadings = [
  { id: "location_title", value: "Location Title" },
  { id: "location_address", value: "Location Address" },
  { id: "loc_status", value: "Status" },
  { id: "added_by", value: "Added By" },
  { id: "action", value: "Actions" },
];

const actions = [
  {
    id: "view_location",
    title: "View Location",
    icon: <Icons.ViewIcon />,
  },
];
const sortingOptions = [
  { id: 0, value: "", text: "All" },
  { id: 1, value: 1, text: "Approved" },
  { id: 2, value: 2, text: "Rejected" },
];

export { actions, columnHeadings, sortingOptions };
