import classNames from "classnames";
import { useSelector } from "react-redux";

import { Icons } from "assets";

import styles from "./style.module.scss";

const BreadCrumb = () => {
  const { crumbs } = useSelector((state: any) => state.root.breadcrumbSlice);
  return (
    <>
      <div className="flex items-center">
        {crumbs?.map(
          (item: { title: string; action?: () => void }, indx: number) => {
            return (
              <div
                key={indx}
                className={classNames("flex flex-row items-center")}
              >
                <div
                  className={
                    crumbs?.length == 1
                      ? styles.breadCrumbActive
                      : indx < crumbs.length - 1
                      ? styles.breadCrumbDefault
                      : styles.breadCrumbActive
                  }
                  key={indx}
                  onClick={item?.action}
                  role="button"
                >
                  {item?.title}
                </div>
                {crumbs?.length - 1 > indx ? (
                  <span className={`w-4 h-4 ${styles.svgWrappper}`}>
                    <Icons.BreadCrumb />
                  </span>
                ) : null}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default BreadCrumb;
