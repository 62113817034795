import { Dialog } from "@headlessui/react";
import React from "react";

interface AddNewPinModalProps {
  showModal: boolean;
  setShowModal: any;
}

const AddNewPinModal = ({ showModal, setShowModal }: AddNewPinModalProps) => {
  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white">
          <div className="p-4">
            <h1 className="text-center">Add New Pin Modal</h1>
          </div>

          {/* ... */}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default AddNewPinModal;
