import React from "react";
import { Tooltip } from "react-tooltip";

interface CustomToolTipProps {
  content: string;
  icon: any;
  index: number;
}

const CustomTooltip = ({ content, icon, index }: CustomToolTipProps) => {
  return (
    <>
      <span data-tooltip-id={`my-tooltip${index}`}>{icon}</span>
      <Tooltip
        style={{
          backgroundColor: "#101828",
          color: "white",
          fontSize: "12px",
          fontWeight: "400",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
        id={`my-tooltip${index}`}
        content={content}
        place="top"
      ></Tooltip>
    </>
  );
};

export default CustomTooltip;
