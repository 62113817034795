import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Mail2 } from "./mail-2.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Eye } from "./eye.svg"
import { ReactComponent as DisableEye } from "./eye-disable.svg";
import { ReactComponent as DuplicateBackArrows } from "./duplicateBackArrows.svg"
import { ReactComponent as LocationIcon } from "./locationIcon.svg";
import { ReactComponent as GuidersIcon } from "./guidersIcon.svg";
import { ReactComponent as UserIcon } from "./userIcon.svg";
import { ReactComponent as MenuIcon } from "./hamburgerMenu.svg";
import { ReactComponent as DropDownIcon } from "./dropdownIcon.svg";
import { ReactComponent as LinkIcon } from "./linkIcon.svg";
import { ReactComponent as SearchIcon } from "./Search.svg";
import { ReactComponent as ViewIcon } from "./eye.svg";
import { ReactComponent as DeactivateUserIcon } from "./user-block.svg";
import { ReactComponent as SortIcon } from "./sort-arrow-down.svg";
import { ReactComponent as NextIcon } from "./next.svg";
import { ReactComponent as PreviousIcon } from "./previous.svg";
import { ReactComponent as AddIcon } from "./addIcon.svg";
import { ReactComponent as ApproveIcon } from "./acceptIcon.svg";
import { ReactComponent as RejectIcon } from "./rejectIcon.svg";
import { ReactComponent as FlagIcon } from "./flagIcon.svg";
import { ReactComponent as FavouriteIcon } from "./Heart.svg";
import { ReactComponent as Heart2 } from "./Heart2.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as Phone } from "./Call.svg";
import { ReactComponent as Magnify } from "./magnify.svg";
import { ReactComponent as ArrowLeft } from "./arrowLeft.svg";
import { ReactComponent as ArrowRight } from "./arrowRight.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Photo } from "./photo.svg";
import { ReactComponent as Desk } from "./desk.svg";
import { ReactComponent as VehicleShip } from "./vahicleShip.svg";
import { ReactComponent as Address } from "./Location.svg";
import { ReactComponent as Gmail } from "./gmail.svg";
import { ReactComponent as AddressIcon } from "./addressIcon.svg";
import { ReactComponent as ClosedEye } from "./eyeClosed.svg";
import { ReactComponent as UploadPhoto } from "./camera-plus.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as DashboardIcon } from "./dashboardIcon.svg";
import { ReactComponent as PinIcon } from "./pin.svg";
import { ReactComponent as GuidersIcon2 } from "./guiders.svg";
import { ReactComponent as BreadCrumb } from "./chevronRight.svg";
import { ReactComponent as NoLocation } from "./noLocation.svg";
import { ReactComponent as NoGuider } from "./noGuider.svg";
import { ReactComponent as Activate } from "./user-check.svg";
import { ReactComponent as Search } from "./search 01.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Amenities } from "./amenities.svg";
import { ReactComponent as EditAmenity } from "./edit-rectangle.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as NoAmenity } from "./NoAmenity.svg";
import { ReactComponent as Reports } from "./reports.svg";
import { ReactComponent as Reviews } from "./reviews.svg";
import { ReactComponent as Featured } from "./bookmark.svg";
import { ReactComponent as RemoveFeatured } from "./bookmark1.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Delete } from "./trashTable.svg";
import { ReactComponent as Support } from "./supportMessage.svg";
import { ReactComponent as TermsAndPolicy } from "./termsAndPolicy.svg";


const Icons = {
  Logo,
  Mail2,
  Mail,
  Lock,
  Eye,
  DisableEye,
  DuplicateBackArrows,
  LocationIcon,
  GuidersIcon,
  UserIcon,
  MenuIcon,
  DropDownIcon,
  LinkIcon,
  SearchIcon,
  ViewIcon,
  DeactivateUserIcon,
  SortIcon,
  NextIcon,
  PreviousIcon,
  AddIcon,
  ApproveIcon,
  RejectIcon,
  FlagIcon,
  FavouriteIcon,
  Share,
  Heart2,
  Phone,
  Magnify,
  Cross,
  ArrowLeft,
  ArrowRight,
  Photo,
  Desk, VehicleShip,
  Gmail,
  Address,
  AddressIcon,
  ClosedEye,
  UploadPhoto,
  Settings,
  DashboardIcon,
  PinIcon,
  GuidersIcon2,
  BreadCrumb,
  NoLocation,
  NoGuider,
  Activate,
  Search,
  Info,
  Amenities,
  EditAmenity,
  Trash,
  NoAmenity,
  Reports,
  Reviews,
  Featured,
  RemoveFeatured,
  Pencil,
  Delete,
  Support,
  TermsAndPolicy
};

export { Icons };
