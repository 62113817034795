import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { Icons } from "assets";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./style.module.scss";
import { routeConstant } from "shared/routes/routesConstant";
import { randomIntFromInterval } from "shared/utils/helpers";
import { Link } from "react-router-dom";

interface LocationCardProps {
  showFavourite: boolean;
  status: string;
  gmu: string;
  images: any;
  name: string;
  id: any;
}

const LocationCard = ({
  showFavourite,
  status,
  gmu,
  images,
  name,
  id,
}: LocationCardProps) => {
  return (
    <Link
      target="_blank"
      to={routeConstant?.place_details.path.replace(":id", id)}
    >
      <div className="p-[10px] pb-6 border border-LOCATION_CARD_BORDER  rounded-lg bg-white">
        <div className={`${styles.sliderWrapper} mb-[18px] gap-2`}>
          <Swiper
            style={{
              "--swiper-pagination-color": "rgba(255,255,255,1)",
              "--swiper-pagination-bullet-inactive-color":
                "rgba(255,255,255,.3)",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "6px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: randomIntFromInterval(3000, 6000),
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {images?.map((img: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div className="relative h-40 lg:h-64 ">
                    {showFavourite ? (
                      <div
                        // onClick={handleIsFavourite}
                        className="flex justify-center items-center absolute z-10 top-[10px] right-[10px] w-9 h-9 rounded-full bg-BLACK bg-opacity-20 hover:cursor-pointer"
                      >
                        <Icons.FavouriteIcon />
                      </div>
                    ) : null}
                    <img
                      className="rounded-lg  w-full h-full transition-all delay-150 block object-cover"
                      src={img}
                      alt={`Slider Image${index + 1}`}
                      loading="lazy"
                    ></img>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* </Slider> */}
        </div>
        <div className="flex flex-col gap-2">
          {/* <span className="text-xs font-medium text-SUCCESS bg-SUCCESS_BG p-2 inline self-start rounded-2xl">
          {status}
        </span> */}
          <h3 className="text-base text-BLACK leading-5 font-medium whitespace-pre overflow-ellipsis overflow-hidden md:text-lg">
            {name}
          </h3>
          <p className="text-LIGHT_GRAY2 font-medium text-xs md:text-sm">
            Type: <span className="text-BLACK font-normal">{gmu}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default LocationCard;
