import React from "react";
import Footer from "shared/components/footer";
import SecondaryHeader from "shared/components/secondaryHeader";
// import styles from "./style.module.scss";

interface FullPageProps {
  Component: any;
  path: any;
}

const FullPage = ({ Component, path }: FullPageProps) => {
  return (
    <main className="">
      <header className="bg-white box-border shadow-[0_0_25px_0_#445E841A]">
        <SecondaryHeader />
      </header>
      <section className="w-11/12 mx-auto min-h-[calc(100vh-155px)] py-10 md:pt20 max-w-6xl md:mx-auto">
        <Component />
      </section>
      <footer className="bg-white box-border shadow-[0_0_25px_0_#445E841A] ">
        <Footer></Footer>
      </footer>
    </main>
  );
};

export default FullPage;
