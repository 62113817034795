import React from "react";

import { Menu } from "@headlessui/react";

import CustomButton from "../customButton";

import { Icons } from "assets";

interface CustomTableProps {
  setSearch: any;
  heading: string;
  subHeading: string;
  setSort?: any;
  search: any;
  sortingOptions?: any;
  showModalButton?: boolean;
  setOpenModal?: any;
  sortText?: any;
  showFilter: boolean;
  setActivePage: any;
  modalButtonText?: string;
}

const CustomTableHeader = ({
  showFilter,
  heading,
  sortText,
  subHeading,
  setSearch,
  setSort,
  search,
  sortingOptions,
  showModalButton,
  setOpenModal,
  setActivePage,
  modalButtonText,
}: CustomTableProps) => {
  return (
    <div className="border border-LOCATION_CARD_BORDER border-b-0 rounded-bl-none rounded-br-none rounded-2xl">
      <div className="flex flex-col px-6 py-5 gap-4 md:flex-row md:flex-wrap md:justify-between md:items-center">
        <div className="bg-white">
          <h1 className="text-lg font-medium text-BLACK">{heading}</h1>
          <p className="text-sm font-normal text-GRAY">{subHeading}</p>
        </div>
        <div className="flex flex-row gap-3 flex-wrap items-center md:flex-row md:justify-between md:flex-nowrap">
          <div className="border cursor-pointer w-full flex justify-between border-LOCATION_CARD_BORDER px-4 py-3 rounded-lg md:min-w-[320px]">
            <input
              onChange={(e) => {
                setActivePage(1);
                setSearch(e.target.value);
              }}
              className="flex-1  text-GRAY text-sm focus:border-none focus:outline-none"
              type="text"
              placeholder="Search & explore by Name"
              value={search}
            ></input>
            <p>
              <Icons.SearchIcon />
            </p>
          </div>
          {/* Sorting */}
          {showFilter && (
            <div className="relative">
              <Menu>
                <Menu.Button>
                  <div className="flex gap-2 items-center px-4 py-3 border border-LOCATION_CARD_BORDER rounded-lg whitespace-pre">
                    <span className="text-GRAY text-sm">
                      {sortText === "" || sortText === 0
                        ? "All"
                        : !sortText
                        ? "Sort By"
                        : sortingOptions.find(
                            (option: any) => option.value === sortText
                          ).text}
                    </span>
                    <span>
                      <Icons.SortIcon />
                    </span>
                  </div>
                </Menu.Button>
                <Menu.Items className=" absolute flex flex-col gap-2 p-4 shadow-md rounded-lg bg-white text-GRAY z-10 hover:cursor-pointer">
                  {sortingOptions?.map((option: any) => {
                    return (
                      <Menu.Item key={option.id}>
                        {({ active }) => (
                          <span
                            onClick={() => {
                              setSort(option?.value);
                              setActivePage(1);
                            }}
                            className={`${active && "text-SECONDARY"}`}
                          >
                            {option?.text}
                          </span>
                        )}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Menu>
            </div>
          )}
          {showModalButton && (
            <div>
              <button
                onClick={() => setOpenModal(true)}
                className="flex gap-[6px] text-sm w-max items-center text-white py-3 px-4 bg-SECONDARY rounded-lg transition-all ease-linear hover:opacity-80"
              >
                <span>
                  <Icons.AddIcon />
                </span>
                <span>{modalButtonText}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomTableHeader;
