import { Icons } from "assets/icons";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useDebounce from "shared/customHooks/useDebounce";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import { routeConstant } from "shared/routes/routesConstant";
import { getSearchItems } from "shared/services/adminService";
import GeneralSkeleton from "shared/skeletons/generalSkeleton";

import styles from "./style.module.scss";

const SearchComponent = () => {
  const [endReach, onScroll] = useOnScroll("search-locations");
  const [search, setSearch] = useState<string>("");
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchItems, setSearchItems] = useState<any>([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const pageRef = useRef<number>(1);
  const locationsRef = useRef<any[]>([]);
  const [moreLoad, setMoreLoad] = useState<boolean>(true);

  const getSearchResults = () => {
    setSearchLoader(true);
    getSearchItems({
      search: searchText,
      activePage: pageRef.current,
      itemsPerPage,
    })
      .then(({ data, status }) => {
        if (status) {
          let temp = [...locationsRef.current, ...data];
          setSearchLoader(false);
          setSearchItems(temp);
          locationsRef.current = temp;
          if (data?.length === 0) {
            setMoreLoad(false);
          } else {
            setMoreLoad(true);
          }
        }
      })
      .catch((err) => {
        console.log("Err--", err);
        setSearchLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    if (endReach && !searchLoader && moreLoad) {
      pageRef.current++;
      setSearchLoader(true);
      getSearchResults();
    }
  }, [endReach]);

  useEffect(() => {
    if (searchText) {
      getSearchResults();
      pageRef.current = 1;
      locationsRef.current = [];
    }
  }, [searchText]);

  return (
    <div className="min-w-[270px] md:min-w-[342px]  z-10 box-border p-[11px] relative h-10 rounded-lg  border border-[#D8D8D8]">
      <div className="flex items-center gap-[15px] h-full">
        <span>
          <Icons.Search />
        </span>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-1 text-sm text-GRAY focus:outline-none h-full"
          type="text"
          placeholder="Search & Explore"
        />
        {search && (
          <span
            className={`cursor-pointer pl-2 border-l border-LIGHT_GRAY2 ${styles.crossIconWrapper}`}
            onClick={() => {
              setSearch("");
              locationsRef.current = [];
              setActivePage(1);
            }}
          >
            <Icons.Cross />
          </span>
        )}
      </div>
      {search && (
        <div className="absolute w-full rounded-md top-11 left-0 bg-[#f7f8f8] font-medium leading-3 p-3">
          <div className="flex gap-2">
            <span className={styles.iconWrapper}>
              <Icons.Info />
            </span>
            <span className="text-PRIMARY text-[9px]">Location Results</span>
          </div>
          <div
            onScroll={onScroll}
            id="search-locations"
            className={`h-max max-h-[350px] overflow-x-hidden overflow-scroll ${styles.searchLocations}`}
          >
            {searchItems?.length > 0 &&
              locationsRef?.current?.map((item: any, index: number) => {
                return (
                  <Link
                    to={`${routeConstant?.place_details?.path.replace(
                      ":id",
                      item._id
                    )}`}
                    target="_blank"
                    key={index}
                    className="flex justify-between py-3 md:py-5 border-b border-BORDER2"
                  >
                    <div>
                      <div className="text-[9px] max-w-48 font-medium leading-4 md:text-xs md:leading-6 text-BLACK">
                        {item?.title}
                      </div>
                      <div className="text-[9px] max-w-48 font-medium leading-4 md:text-xs md:leading-6 text-GRAY">
                        {item?.address}
                      </div>
                    </div>
                    <figure className="w-16 h-16 md:w-20 md:h-20">
                      <img
                        className="w-full h-full object-cover block rounded-lg"
                        src={item?.pictures[0]}
                        loading="lazy"
                      />
                    </figure>
                  </Link>
                );
              })}
            {searchLoader &&
              Array.from({ length: 3 }).map((_, i) => {
                return (
                  <div
                    key={i}
                    className="py-3 flex justify-between items-center"
                  >
                    <div className="flex flex-col gap-2">
                      <GeneralSkeleton className="w-28 md:w-44 h-3 bg-gray-200" />
                      <GeneralSkeleton className="w-32 md:w-52 h-3 bg-gray-200" />
                    </div>
                    <div>
                      <GeneralSkeleton className="w-16 h-16 bg-gray-200 rounded-2xl" />
                    </div>
                  </div>
                );
              })}
            {searchItems.length === 0 && !searchLoader && (
              <div className="flex h-52 items-center justify-center">
                <span className="text-sm md-text-base text-BLACK">
                  No Location Found
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
