import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";

import AccountInfo from "shared/components/accountInfo";
import ChangePassword from "shared/components/changePassword";
import CustomTabs from "shared/components/customTabs";

import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Settings",
            action: () => {
              navigate(routeConstant.settings.path);
            },
          },
        ],
      })
    );
  }, []);

  return (
    <div className="py-10 px-8 bg-white rounded-lg shadow-lg 2xl:max-w-[1500px]">
      <h1 className="text-xl text-BLACK font-medium mb-8">Settings</h1>
      <div className="tabs-wrapper">
        <Tab.Group>
          {/* Custom Wrapper */}
          <CustomTabs
            tabs={[
              { id: "account", title: "Account" },
              { id: "change_password", title: "Change Password" },
            ]}
          />
          {/* Panels */}
          <Tab.Panels>
            <Tab.Panel>
              <AccountInfo />
            </Tab.Panel>
            <Tab.Panel>
              <ChangePassword />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Settings;
