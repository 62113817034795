import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import ImagesSection from "shared/components/imagesSection";
import MapComponent from "shared/components/mapComponent";
import GuiderProfileCard from "shared/components/guiderProfileCard";

import { getLocationDetails } from "shared/services/adminService";
import { Icons, Images } from "assets";
import { images, aminities } from "./constants";

import styles from "./style.module.scss";
import { toastMessage } from "shared/components/toast";
import GeneralSkeleton from "shared/skeletons/generalSkeleton";

const PlaceDetail = () => {
  const { id } = useParams();
  const [locationDetails, setLocationDetails] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const [locationCount, setLocationCount] = useState<number>(0);
  const {
    title,
    created_by,
    pictures,
    phone,
    description,
    amenities,
    birthCapacity,
  } = locationDetails;

  const getLocationInfo = (id: any) => {
    setLoader(true);
    getLocationDetails(id)
      .then(({ status, data }) => {
        if (status) {
          const { location, locCount } = data;
          setLocationCount(locCount);
          setLocationDetails(location);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        setLoader(false);
        toastMessage("error", "Something went wrong!");
      });
  };

  useEffect(() => {
    getLocationInfo(id);
  }, []);

  return (
    <div>
      <section
        className={classNames("flex flex-col gap-2 mb-9", styles.topSection)}
      >
        <h1 className={classNames(styles.title)}>
          {loader ? (
            <GeneralSkeleton className="h-4 bg-gray-200 rounded-full w-[80%] mb-2" />
          ) : title ? (
            title
          ) : (
            "Not Provided"
          )}
        </h1>
        <div
          className={classNames(
            "flex items-center justify-between flex-wrap gap-3"
          )}
        >
          <div className={classNames("flex items-center gap-3 flex-wrap")}>
            <div className={classNames("flex items-center gap-1")}>
              {loader ? (
                <GeneralSkeleton className="h-6 bg-gray-200 rounded-full w-6" />
              ) : (
                <img
                  src={
                    created_by?.profile_picture_full_path
                      ? created_by?.profile_picture_full_path
                      : Images.BlankProfile
                  }
                  height={32}
                  width={32}
                  className={classNames(styles.avt)}
                />
              )}
              <label className={classNames(styles.userName)}>
                by
                <span>
                  {loader ? (
                    <GeneralSkeleton className="h-4 bg-gray-200 rounded-full w-8" />
                  ) : (
                    `${created_by?.firstname ? created_by?.firstname : ""} ${
                      created_by?.lastname ? created_by?.lastname : ""
                    }`
                  )}
                </span>
              </label>
            </div>
            <div className={classNames("flex items-center gap-1")}>
              <Icons.Phone className={classNames(styles.phoneIcon)} />
              <label className={classNames(styles.phoneLabel)}>
                {loader ? (
                  <GeneralSkeleton className="h-4 bg-gray-200 rounded-full w-36" />
                ) : phone ? (
                  <a href={`tel:${phone}`}>{phone}</a>
                ) : (
                  "Not Provided"
                )}
              </label>
            </div>
          </div>
          {/* <div className={classNames("flex items-center gap-3")}>
            <div className={classNames(styles.actionContainer)}>
              <Icons.Heart2 />
            </div>
            <div className={classNames(styles.actionContainer)}>
              <Icons.Share />
            </div>
          </div> */}
        </div>
      </section>
      <ImagesSection list={pictures ? pictures : []} />
      <section className={classNames("flex flex-col md:flex-row gap-6 mt-12")}>
        <div className={classNames("w-full md:w-2/3 flex flex-col")}>
          <div
            className={classNames(
              "flex flex-col gap-4",
              styles.descriptionContainer
            )}
          >
            <h3>Description</h3>
            <p>
              {loader ? (
                <>
                  {Array.from({ length: 7 }).map((_, index) => (
                    <GeneralSkeleton
                      key={index}
                      className="h-3 bg-gray-200 rounded-full w-full mb-3"
                    />
                  ))}
                </>
              ) : description ? (
                description
              ) : (
                ""
              )}
            </p>
            {/* <p>
              Tincidunt id nisl nec eget in maecenas posuere. Sollicitudin lacus
              id morbi tortorxzxc vitae dolor eget. Nisl non amet donec
              consectetur lobortis nulla quis quamcdfefeffedsvsvs vdv elementum.
              Non enim vitae amet maecenas faucibus tempuscd fvfdvdfbvdf
              bfbdffcdcd habitasse.elementum. Non enim vitae amet maecenas
              faucibus tempusvdvds fdbf habitasse.elementum. Non enim vitae.
            </p> */}
          </div>
          <hr className={classNames("my-6")} />

          <div
            className={classNames(
              styles.aminityContainer,
              "flex flex-col gap-4"
            )}
          >
            <h3>Amenities</h3>
            <div className={classNames("grid  grid-cols-2")}>
              {loader ? (
                Array.from({ length: 6 }).map((_, inx) => {
                  return (
                    <div key={inx} className="flex gap-4 items-center mb-3">
                      <GeneralSkeleton className="w-6 h-6 bg-gray-200 rounded-full" />
                      <GeneralSkeleton className=" w-36 h-2.5  bg-gray-200 rounded-full" />
                    </div>
                  );
                })
              ) : amenities ? (
                amenities.map((itm: any, inx: number) => {
                  const { amenity, availability } = itm;
                  return (
                    <div
                      className={classNames(
                        styles.singleAminity,
                        "w-full xs:w-1/2"
                      )}
                      key={inx}
                    >
                      <span className=" w-6 h-6">
                        <img
                          className="w-full h-full object-cover"
                          src={amenity?.avatar}
                          alt={amenity.name}
                        />
                      </span>
                      <label>{amenity.name}</label>
                      <span>{availability}</span>
                    </div>
                  );
                })
              ) : (
                <div>No Added Amenities</div>
              )}
            </div>
          </div>

          <hr className={classNames("my-6")} />

          <div
            className={classNames("flex flex-col gap-4", styles.berthContainer)}
          >
            <h3>Berth Capacity</h3>
            <div className={classNames(styles.slip, "w-full")}>
              <Icons.Desk />
              <label>Slips:</label>
              <span>
                {loader ? (
                  <GeneralSkeleton className="h-2.5 bg-gray-200 rounded-full w-12" />
                ) : birthCapacity ? (
                  birthCapacity
                ) : (
                  "Not Provided"
                )}
              </span>
            </div>
          </div>

          <hr className={classNames("my-6")} />
        </div>
        <div
          className={classNames(
            styles.guiderProfileContainer,
            "flex flex-col gap-4 basis-1/3 mt-0"
          )}
        >
          <div
            className={classNames(
              styles.mapContainer,
              "flex flex-col gap-4 relative"
            )}
          >
            <h3>View Map</h3>
            <MapComponent
              containerStyle={classNames(styles.mapContainerElement)}
              data={locationDetails ? locationDetails : {}}
            />
          </div>
          <h3>Guider Profile</h3>
          <GuiderProfileCard
            count={locationCount}
            loader={loader}
            user={created_by ? created_by : {}}
          />
        </div>
      </section>
    </div>
  );
};

export default PlaceDetail;
