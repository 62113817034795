import React from "react";

import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { useSelector } from "react-redux";

import { Icons, Images } from "assets";
import { resetUser } from "shared/redux/reducers/userSlice";
import { useDispatch } from "react-redux";

interface HeaderDropDownProps {
  menuItems: any;
}

const HeaderDropDown = ({ menuItems }: HeaderDropDownProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.root.userSlice);
  const handleLogout = () => {
    dispatch(resetUser());
  };
  return (
    <Menu>
      <div className="relative">
        <Menu.Button>
          <div className="flex gap-[10px] items-center">
            <div className="rounded-full w-8 h-8">
              <img
                className="w-full h-full object-cover rounded-full aspect-[1]"
                alt={user?.user?.firstname ? user?.user?.firstname : ""}
                src={
                  user?.user?.profile_picture_full_path
                    ? user?.user?.profile_picture_full_path
                    : Images.BlankProfile
                }
              ></img>
            </div>
            <div className="hidden md:flex flex-col gap-[3px] leading-[14px] items-start">
              <p className="text-sm font-neueMedium font-medium text-BLACK">{`${
                user?.user?.firstname ? user?.user?.firstname : ""
              } ${user?.user?.lastname ? user?.user?.lastname : ""}`}</p>
              <p className="text-[10px] font-normal text-GRAY leading-[12px]">
                {user?.user?.email ? user?.user?.email : ""}
              </p>
            </div>
            <span className=" self-start">
              <Icons.DropDownIcon />
            </span>
          </div>
        </Menu.Button>
        <Menu.Items
          className={`flex flex-col gap-4 z-[9999] p-4 absolute right-[-5px] w-max  bg-white rounded-md shadow-md`}
        >
          {menuItems.map((menuItem: any, index: number) => {
            return (
              <Menu.Item key={index}>
                {({ active }) => (
                  <Link
                    className={`${active && "text-SECONDARY"}`}
                    to={menuItem.link}
                  >
                    {menuItem.menuText}
                  </Link>
                )}
              </Menu.Item>
            );
          })}
          <Menu.Item>
            {({ active }) => (
              <span
                onClick={handleLogout}
                className={`${active && "text-SECONDARY"}`}
              >
                Logout
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default HeaderDropDown;
