import React, { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { Icons } from "assets/icons";
import Spinner from "shared/components/spinner";

import styles from "./style.module.scss";

interface ConfirmationProps {
  handleConfirm: any;
  isOpen: boolean;
  text: string;
  close: any;
  loader: boolean;
  subText: string;
}

const Confirmation = ({
  handleConfirm,
  isOpen,
  text,
  close,
  loader,
  subText,
}: ConfirmationProps) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        // open={viewModal}
        onClose={() => close()}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto w-[550px] max-w-[90vw]  py-4  rounded-md bg-white  md:max-w-xl">
              <div className="relative mb-3">
                <span className="block text-center font-neueMedium text-sm font-semibold leading-5 text-BLACK">
                  Confirmation
                </span>
                <span
                  className={`${styles.iconWrapper} hover:cursor-pointer absolute right-[13px] top-0`}
                  onClick={() => close()}
                >
                  <Icons.Cross />
                </span>
              </div>
              <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
              <Dialog.Title className="font-bold text-center text-base md:text-[1.25rem] my-4 px-6">
                {text}
              </Dialog.Title>
              <div className="px-4 flex gap-1 justify-center items-center mb-5">
                <span>
                  <Icons.Info />
                </span>
                <span className="text-[9px] md:text-xs text-GRAY leading-4">
                  {subText}
                </span>
              </div>
              {/* <div className="text-GRAY text-xs md:text-sm font-medium mb-2  max-w-64 mx-auto">
                Clicking on the confirm button will permanently delete the
                selected entry
              </div> */}
              <Dialog.Description className="px-6">
                <div className="flex gap-4 md:gap-8 justify-center mb-4">
                  <button
                    className="text-sm px-6 py-2 text-SECONDARY font-medium border border-SECONDARY rounded-xl   bg-white focus:outline-none md:text-base md:px-12"
                    onClick={() => close()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-PRIMARY px-6 py-2 font-medium rounded-xl text-white hover:opacity-80 focus:outline-none md:px-12"
                    onClick={handleConfirm}
                  >
                    {loader ? <Spinner /> : "Confirm"}
                  </button>
                </div>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Confirmation;
