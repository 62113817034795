export const columnHeadings = [
    { id: "user", value: "User" },
    { id: "total_location", value: "Total Locations" },
    { id: "reported_by", value: "Reported By" },
    { id: "report_reason", value: "Report Reason" },
    { id: "action", value: "Actions" },
];


export const sortingOptions = [
    { id: 2, value: 1, text: "A-Z" },
    { id: 1, value: -1, text: "Z-A" }
]