import React, { useState } from "react";
import { useFormik } from "formik";

import CustomInputField from "../customInputField";

import { passwordValidationSchema } from "shared/utils/validation";
import { Icons } from "assets";
import Spinner from "../spinner";
import { updatePassword } from "shared/services/adminService";
import { toastMessage } from "../toast";

const ChangePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const handleUpdatePassword = () => {
    formik.setSubmitting(true);
    let paylaod = {
      currentPassword: formik.values.currentPassword,
      newPassword: formik.values.newPassword,
    };
    updatePassword(paylaod)
      .then(({ data, status }) => {
        if (status) {
          formik.resetForm();
          toastMessage("success", "Password Updated Successfully");
        }
      })
      .catch((err) => {
        toastMessage("error", err);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: passwordValidationSchema,
    onSubmit: () => {
      handleUpdatePassword();
    },
  });

  return (
    <div className="max-w-[520px]">
      <h1 className=" text-lg font-bold text-BLACK mb-8">Change Password</h1>
      <form
        // onSubmit={(e) => onSubmit(e, formik.values)}
        className="grid grid-cols-1 gap-5"
        onSubmit={(e) => e.preventDefault()}
      >
        <CustomInputField
          required={true}
          htmlFor="currentPassword"
          labelText="Current Password"
          value={formik.values.currentPassword}
          setInputValue={formik.handleChange}
          error={formik.errors.currentPassword}
          onBlur={formik.handleBlur}
          icon={<Icons.Lock />}
          name="currentPassword"
          touched={formik.touched.currentPassword}
          type={showCurrentPassword ? "text" : "password"}
          showPassword={showCurrentPassword}
          placeHolder="• • • • • •"
          setShowPassword={setShowCurrentPassword}
          passwordType={true}
        />
        <CustomInputField
          required={true}
          htmlFor="newPassword"
          labelText="New Password"
          value={formik.values.newPassword}
          setInputValue={formik.handleChange}
          error={formik.errors.newPassword}
          onBlur={formik.handleBlur}
          icon={<Icons.Lock />}
          name="newPassword"
          touched={formik.touched.newPassword}
          type={showNewPassword ? "text" : "password"}
          showPassword={showNewPassword}
          placeHolder="• • • • • •"
          passwordType={true}
          setShowPassword={setShowNewPassword}
        />
        <CustomInputField
          required={true}
          htmlFor="confirmPassword"
          labelText="Confirm Password"
          value={formik.values.confirmPassword}
          setInputValue={formik.handleChange}
          error={formik.errors.confirmPassword}
          onBlur={formik.handleBlur}
          icon={<Icons.Lock />}
          name="confirmPassword"
          touched={formik.touched.confirmPassword}
          type={showConfirmPassword ? "text" : "password"}
          showPassword={showConfirmPassword}
          placeHolder="• • • • • •"
          setShowPassword={setShowConfirmPassword}
          passwordType={true}
        />
        <button
          className=" w-fit mt-5 justify-self-center md:justify-self-end text-center text-white text-base font-medium py-4 px-11 bg-PRIMARY rounded-lg hover:opacity-80 transition-all ease-linear delay-150"
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? <Spinner /> : "Save Changes"}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
