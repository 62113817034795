import React from "react";

import { Link } from "react-router-dom";

import { Icons, Images } from "assets";
import { routeConstant } from "shared/routes/routesConstant";

interface GuiderCardProps {
  guiderName: string;
  locations: number;
  image: any;
  id: number;
}

const GuiderCard = ({ guiderName, locations, image, id }: GuiderCardProps) => {
  return (
    <div className="py-3 px-4 border-t last:pb-0">
      <div className="flex gap-2">
        <div className="w-14 h-14">
          <img
            className="w-full h-full object-cover block rounded-full aspect-[1]"
            src={image ? image : <Images.BlankUserPlaceHolder />}
            alt={guiderName}
            loading="lazy"
          ></img>
        </div>
        <div className="flex flex-col gap-[10px]">
          <p className="text-xs md:text-sm text-BLACK font-medium leading-4">
            {guiderName}
          </p>
          <p className="text-[10px] text-LIGHT_GRAY2 font-normal leading-4 md:text-xs">
            Total Locations: <span className="text-BLACK">{locations}</span>
          </p>
        </div>
        <Link
          to={routeConstant?.guide_detail.path.replace(":id", `${id}`)}
          target="_blank"
          className="ml-auto cursor-pointer"
        >
          <Icons.LinkIcon />
        </Link>
      </div>
    </div>
  );
};

export default GuiderCard;
