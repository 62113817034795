import { Icons } from "assets";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";
import CustomTableHeader from "shared/components/customTableHeader";
import { Tab } from "@headlessui/react";
import LocationServiceType from "shared/components/locationServiceType";
import LocationTypeModal from "shared/modals/locationTypeModal";
import ServiceTypesTable from "shared/components/serviceTypesTable";
import useDebounce from "shared/customHooks/useDebounce";

const ServiceTypes = () => {
  const tabs = [
    { id: "location_types", value: 0, title: "Location Types" },
    { id: "service_types", value: 1, title: "Service Types" },
  ];
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  // const [sort, setSort] = useState<number>(1);
  const [openLocationTypeModal, setOpenLocationTypeModal] =
    useState<boolean>(false);
  const [openAddServiceTypeModal, setOpenAddServiceTypeModal] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [tabId, setTabId] = useState<number>(tabs[0].value);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  return (
    <section>
      <CustomTableHeader
        // sortingOptions={[]}
        heading="Manage Types"
        subHeading="Showing the list of location & service types"
        showModalButton={true}
        modalButtonText="Add New Type"
        setSearch={setSearch}
        search={search}
        // setSort={setSort}
        showFilter={false}
        setOpenModal={
          tabId === tabs[0].value
            ? setOpenLocationTypeModal
            : setOpenAddServiceTypeModal
        }
        setActivePage={setActivePage}
      />
      <div>
        <Tab.Group
          onChange={(index) => {
            setTabId(index);
            setSearch("");
          }}
        >
          {/* Custom Wrapper */}
          <Tab.List className="border border-b-0 border-LOCATION_CARD_BORDER  flex gap-8 pt-[14px] pl-4">
            {tabs.map((tab: any) => {
              return (
                <Tab
                  key={tab.id}
                  className="focus-visible:border-none focus-visible:outline-none"
                >
                  {({ selected }: any) => {
                    return (
                      <span
                        className={
                          selected
                            ? " whitespace-pre border-b border-SECONDARY pb-3  text-SECONDARY text-[15px] font-medium block"
                            : " whitespace-pre bg-white text-GRAY font-normal pb-3 text-[15px] block"
                        }
                      >
                        {tab.title}
                      </span>
                    );
                  }}
                </Tab>
              );
            })}
          </Tab.List>
          {/* Panels */}
          <Tab.Panels>
            <Tab.Panel>
              <LocationServiceType
                searchText={searchText}
                openLocationTypeModal={openLocationTypeModal}
                setOpenLocationTypeModal={setOpenLocationTypeModal}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ServiceTypesTable
                searchText={searchText}
                openAddServiceTypeModal={openAddServiceTypeModal}
                setOpenAddServiceTypeModal={setOpenAddServiceTypeModal}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export default ServiceTypes;
