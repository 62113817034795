import { Dialog, Transition } from "@headlessui/react";
import { Icons } from "assets";
import { Fragment, useState } from "react";
import styles from "./style.module.scss";
import Spinner from "shared/components/spinner";
import { toastMessage } from "shared/components/toast";
import {
  approveRequestedLocation,
  rejectRequestedLocation,
} from "shared/services/adminService";

interface ConfirmationProps {
  viewModal: boolean;
  setCloseModal: any;
  text?: string;
  type?: string;
  locationId?: number;
  setConfirmAction?: any;
}

const Confirmation = ({
  setCloseModal,
  viewModal,
  text,
  locationId,
  type,
  setConfirmAction,
}: ConfirmationProps) => {
  const [loader, setLoader] = useState<boolean>(false);

  const handleConfirm = () => {
    setLoader(true);
    const service =
      type === "Approve" ? approveRequestedLocation : rejectRequestedLocation;
    service(locationId)
      .then(({ status, data }) => {
        if (status) {
          setLoader(false);
          toastMessage("success", "Action Performed Successfully");
          setCloseModal();
          setConfirmAction();
        }
      })
      .catch((err) => {
        toastMessage("err", "Something went wrong!");
        setLoader(false);
      });
  };
  return (
    <Transition show={viewModal} as={Fragment}>
      <Dialog
        // open={viewModal}
        onClose={() => setCloseModal()}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-[90vw]  p-4 rounded-md bg-white md:max-w-lg">
              <div className="flex justify-end mb-3">
                <span
                  className={`${styles.iconWrapper} hover:cursor-pointer`}
                  onClick={() => setCloseModal()}
                >
                  <Icons.Cross />
                </span>
              </div>
              <Dialog.Title className="font-bold text-base mb-8 md:text-lg">
                {text}
              </Dialog.Title>
              <Dialog.Description>
                <div className="flex gap-8 justify-center mb-4">
                  <button
                    className="text-sm px-6 py-2 text-SECONDARY font-medium border border-SECONDARY rounded-xl   bg-white focus:outline-none md:text-base md:px-12"
                    onClick={() => setCloseModal()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-PRIMARY px-6 py-2 font-medium rounded-xl text-white hover:opacity-80 focus:outline-none md:px-12"
                    onClick={handleConfirm}
                  >
                    {loader ? <Spinner /> : "Confirm"}
                  </button>
                </div>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Confirmation;
