import { Dialog, Transition } from "@headlessui/react";
import { Icons } from "assets/icons";
import React, { Fragment } from "react";
import styles from "./styles.module.scss";

interface ViewSupportMessageProps {
  isOpen: boolean;
  close: any;
  message: string;
}

const ViewSupportMessageModal = ({
  isOpen,
  close,
  message,
}: ViewSupportMessageProps) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={close} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}

        <div className="fixed inset-0 flex w-screen items-center justify-center py-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              id={styles.panel}
              className="mx-auto max-w-[90vw] w-[500px] h-[50vh] overflow-scroll pb-4 rounded-md bg-white md:max-w-lg"
            >
              <Dialog.Title className="relative">
                <span className="block py-4 text-center text-sm font-semibold leading-5 text-BLACK">
                  Support Message
                </span>
                <span
                  onClick={close}
                  className={`${styles.iconWrapper} absolute right-3 top-1 p-2 cursor-pointer`}
                >
                  <Icons.Cross />
                </span>
              </Dialog.Title>
              <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
              <Dialog.Description className="p-4">
                <div className="border border-LOCATION_CARD_BORDER rounded-lg h-44  overflow-y-scroll  p-2">
                  <span className="text-sm block text-GRAY font-medium  whitespace-normal break-words">
                    {message ? message : ""}
                  </span>
                </div>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ViewSupportMessageModal;
