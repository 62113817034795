import React from "react";

import { Icons } from "assets";

interface GuiderContactCardProps {
  userDetail: any;
  loader: boolean;
}

const GuiderContactCard = ({ userDetail, loader }: GuiderContactCardProps) => {
  const { user, addedLocations } = userDetail;
  return (
    <div className=" px-6 py-8 border border-LOCATION_CARD_BORDER rounded-lg md:w-auto">
      <div className="pb-10 text-lg md:text-xl text-BLACK font-medium  border-b border-LOCATION_CARD_BORDER">
        {user?.firstname} Contact Info
      </div>
      <div className="flex flex-col gap-9 mt-10">
        <div className="flex flex-row gap-2 leading-5 text-GRAY font-normal text-sm md:text-base items-center">
          <span>{<Icons.Gmail />}</span>
          {loader ? (
            <div role="status" className="animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
            </div>
          ) : (
            <a href={`mailto:${user?.email}`}>
              {user?.email ? user?.email : "Not Provided"}
            </a>
          )}
        </div>
        <div className="flex flex-row gap-2 text-GRAY leading-5 font-normal text-sm md:text-base items-center">
          <span>{<Icons.Phone />}</span>
          <span>
            {loader ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
              </div>
            ) : user?.phone ? (
              <a href={`tel:${user?.phone}`}>{user?.phone}</a>
            ) : (
              "Not Provided"
            )}
          </span>
        </div>
        <div className="flex flex-row gap-2 text-GRAY font-normal text-sm md:text-base leading-5 items-center">
          <span>{<Icons.Address />}</span>
          <span>
            {loader ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="h-2.5 bg-gray-200 rounded-full  w-32"></div>
              </div>
            ) : user?.address ? (
              user?.address
            ) : (
              "Not Provided"
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GuiderContactCard;
