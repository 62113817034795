import { Icons, Images } from "assets";
import classNames from "classnames";
import { useRef, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./style.module.scss";

interface ImagePreviewProps {
  images: any[];
  handleClose: () => void;
}

const ImagePreview = ({ images, handleClose }: ImagePreviewProps) => {
  const sliderRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  return (
    <div className={classNames(styles.topContainer)}>
      <div
        className={classNames(styles.customContainer, styles.contentContainer)}
      >
        <div className={classNames("flex items-center justify-between w-full")}>
          <div
            className={classNames(styles.crossContainer)}
            onClick={handleClose}
          >
            <Icons.Cross />
          </div>
          {/* <label className={classNames(styles.count)}>
            {activeIndex + 1} / {images?.length}
          </label> */}
          {/* <div className={classNames("flex items-center gap-3")}>
            <div className={classNames(styles.crossContainer)}>
              <Icons.Heart2 className={classNames(styles.heartIcon)} />
            </div>
            <div className={classNames(styles.crossContainer)}>
              <Icons.Share className={classNames(styles.shareIcon)} />
            </div>
          </div> */}
        </div>
        <div className={classNames("flex gap-4 z-10 items-center")}>
          <div
            className={classNames(styles.crossContainer)}
            onClick={() => {
              sliderRef.current?.slidePrev();
            }}
          >
            <Icons.ArrowLeft className={classNames(styles.arrowIcon)} />
          </div>
          <Swiper
            slidesPerView={1}
            loop={true}
            spaceBetween={30}
            onSwiper={(it) => {
              sliderRef.current = it;
            }}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            modules={[Autoplay]}
            onRealIndexChange={(element) => {
              console.log(element);
              setActiveIndex(element.activeIndex);
            }}
          >
            {images?.map((itm, inx) => {
              return (
                <SwiperSlide key={inx}>
                  <div className={classNames(styles.imgContainer)}>
                    <img
                      src={itm}
                      alt=""
                      height={784}
                      width={1120}
                      className={classNames(styles.imgCover)}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            className={classNames(styles.crossContainer)}
            onClick={() => {
              sliderRef.current?.slideNext();
            }}
          >
            <Icons.ArrowRight className={classNames(styles.arrowIcon)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
