import React from "react";

const GuiderCardSkeleton = () => {
  return Array.from({ length: 5 }).map((i, index) => {
    return (
      <div key={index} className="p-3 flex items-center mt-2">
        <svg
          className="w-10 h-10 me-3 text-gray-200 "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div className="flex-1 gap-2 relative">
          <div className="h-2.5 bg-gray-200 rounded-full  w-32 mb-3"></div>
          <div className=" w-40 h-2 bg-gray-200 rounded-full "></div>
          <div className="absolute -top-1 right-0 w-5 h-5 bg-gray-200 rounded-sm "></div>
        </div>
      </div>
    );
  });
};

export default GuiderCardSkeleton;
