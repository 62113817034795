import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Icons, Images } from "assets";
import styles from "./style.module.scss";

interface ReportDetailModalProps {
  isOpen: boolean;
  close: any;
  report: any;
}

const ReportDetailModal = ({
  isOpen,
  close,
  report,
}: ReportDetailModalProps) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => {
          close();
        }}
        className="relative z-50"
        open={isOpen}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}

        <div className="fixed inset-0 flex w-screen items-center justify-center py-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-[90vw] w-[500px]  pb-4 rounded-md bg-white md:max-w-lg">
              <Dialog.Title className="relative flex  justify-center items-center font-bold text-BLACK leading-4 text-sm  p-4">
                <span className="font-semibold font-neueMedium text-xs md:text-sm leading-4">
                  Report Details
                </span>
                <span
                  id="closeIcon"
                  onClick={close}
                  className={`${styles.iconWrapper} cursor-pointer absolute right-3 top-3`}
                >
                  <Icons.Cross />
                </span>
              </Dialog.Title>
              <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
              <Dialog.Description>
                <div className="px-8  mt-9">
                  <div className="flex gap-4">
                    <figure className="w-16 h-16 md:w-24 md:h-24">
                      <img
                        src={
                          report?.reportedUser?.profile_picture_full_path ? (
                            report?.reportedUser?.profile_picture_full_path
                          ) : (
                            <Images.BlankUserPlaceHolder />
                          )
                        }
                        alt={report?.reportedUser?.firstname}
                        className="w-full h-full block object-cover rounded-full"
                      />
                    </figure>
                    <div className="flex flex-col gap-2 justify-center">
                      <span className="text-BLACK text-base font-medium leading-5 md:text-xl">
                        {report?.reportedUser?.firstname
                          ? report?.reportedUser?.firstname
                          : ""}{" "}
                        {report?.reportedUser?.lastname
                          ? report?.reportedUser?.lastname
                          : ""}
                      </span>
                      <span className=" text-GRAY font-normal text-xs md:text-base ">
                        Guider / Outfitter
                      </span>
                    </div>
                  </div>
                  {/* Locations */}
                  <div className="text-center border border-LOCATION_CARD_BORDER mt-4 py-4 rounded-lg">
                    <span className="text-base md:text-xl text-BLACK font-medium leading-6">
                      {report?.reportedUser?.addedLocationsCount}
                    </span>
                    <span className="block text-base text-GRAY font-normal leading-5 mt-2 ">
                      Locations
                    </span>
                  </div>
                  {/* Reason */}
                  <div className="flex flex-col gap-6 mt-4 px-6 py-4 bg-ICON_BACKGROUND_GRAY">
                    <div className="flex gap-3 items-center">
                      <figure className=" w-10 h-10">
                        <img
                          src={
                            report?.createdBy?.profile_picture_full_path ? (
                              report?.createdBy?.profile_picture_full_path
                            ) : (
                              <Images.BlankUserPlaceHolder />
                            )
                          }
                          className="rounded-full w-full h-full block"
                        />
                      </figure>
                      <div className="flex flex-col">
                        <span className="text-GRAY md:text-[11px] leading-4 font-medium ">
                          Reported By
                        </span>
                        <span className=" text-base text-BLACK font-medium leading-6">
                          {report?.createdBy?.firstname
                            ? report?.createdBy?.firstname
                            : ""}{" "}
                          {report?.createdBy?.lastname
                            ? report?.createdBy?.lastname
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className=" text-GRAY font-medium leading-5 text-xs md:text-sm">
                        Report Reason
                      </span>
                      <div className="font-normal leading-4 text-xs text-BLACK h-[100px] overflow-x-hidden overflow-y-scroll">
                        {report?.reason ? report?.reason : "No  Reason Added"}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReportDetailModal;
