const columnHeadings = [
  { id: "name", value: "Name" },
  { id: "email", value: "Email" },
  { id: "phone", value: "Phone Number" },
  { id: "locations", value: "Locations" },
  { id: "user_status", value: "Status" },
  { id: "action", value: "Actions" },
];

const sortingOptions = [
  { id: 0, value: "", text: "All" },
  { id: 1, value: 1, text: "Active" },
  { id: 2, value: 2, text: "Blocked" },
];

export { columnHeadings, sortingOptions };
