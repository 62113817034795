import React from "react";

interface LocationTableProps {
  actions: number;
}
const LocationTableSkeleton = ({ actions }: LocationTableProps) => {
  return (
    <tr className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY     border-t border-LOCATION_CARD_BORDER">
      <td className="px-6 py-4">
        <div className="animate-pulse">
          <div className="flex gap-2 items-center">
            <div className="h-8 bg-gray-200 rounded-lg  w-8"></div>
            <div>
              <div className="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
              <div className="w-16 h-2 bg-gray-200 rounded-full "></div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full  w-32 "></div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full  w-32 "></div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="animate-pulse">
          <div className="flex gap-2 items-center">
            <div className="h-8 bg-gray-200 rounded-full  w-8"></div>
            <div>
              <div className="h-2.5 bg-gray-200 rounded-full  w-32 "></div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        <span className="flex gap-2 animate-pulse">
          {Array.from({ length: actions }).map((i, index: number) => {
            return (
              <span
                key={index}
                className="block h-6 bg-gray-200   w-6 rounded-full"
              ></span>
            );
          })}
        </span>
      </td>
    </tr>
  );
};

export default LocationTableSkeleton;
