const routeConstant = {
    auth: {
        path: "/",
        title: "Login",
    },
    // ADMIN ROUTES

    dashboardAdmin: {
        path: '/',
        title: 'Dashboard'
    },
    guiders: {
        path: '/guiders',
        title: 'Guiders / Outfitters'
    },
    locations: {
        path: '/locations',
        title: 'Locations',
    },
    settings: {
        path: '/settings',
        title: 'Settings'
    },
    location_requests: {
        path: '/location_requests',
        title: 'Location Requests'
    },
    guide_detail: {
        path: "/guide/:id",
        title: 'Guide Detail'
    },
    place_details: {
        path: '/place/:id',
        title: "Place Detail"
    },
    amenities: {
        path: '/amenities',
        title: 'Manage Amenities'
    },
    reports: {
        path: '/reports',
        title: 'Manage Reports'
    },
    reviews: {
        path: '/reviews',
        title: 'Manage Reviews'
    },
    serviceTypes: {
        path: '/service_types',
        title: 'Service Types'
    },
    getInTouch: {
        path: '/support-tickets',
        title: 'Support Tickets'
    },
    termsAndConditions: {
        path: '/termsAndConditions',
        title: 'Terms And Conditions'
    },
    privacyPolicy: {
        path: '/privacyPolicy',
        title: 'Privacy Policy'
    }

    // ---------------



}

export { routeConstant }