import { Dialog, Transition } from "@headlessui/react";
import { Icons } from "assets/icons";
import React, { Fragment, useEffect } from "react";
import { addLocationTypeSchema } from "shared/utils/validation";
import { useFormik } from "formik";
import Spinner from "shared/components/spinner";
import {
  addLocationType,
  editLocationType,
} from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import styles from "./style.module.scss";

const LocationTypeModal = ({
  viewOnly,
  open,
  close,
  edit,
  locationType,
  getAllLocationTypes,
}: any) => {
  const initialValues = {
    title: edit ? locationType?.title : "",
  };

  const handleSubmit = () => {
    let name = formik.values.title;
    const service = edit ? editLocationType : addLocationType;
    service(name, edit ? locationType?._id : null)
      .then(({ data, status }) => {
        if (status) {
          formik.setSubmitting(false);
          close();
          formik.resetForm();
          getAllLocationTypes();
          toastMessage("success", data);
        }
      })
      .catch((err) => {
        console.log("Error----", err);
        toastMessage("error", "Something went wrong!");
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addLocationTypeSchema,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    if (edit) {
      formik.setFieldValue("title", locationType?.name);
    } else {
      formik.setFieldValue("title", "");
    }
  }, [edit]);

  return (
    <>
      {open && (
        <Transition show={open} as={Fragment}>
          <Dialog
            onClose={() => {
              close();
            }}
            className="relative z-50"
          >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            {/* Full-screen container to center the panel */}

            <div className="fixed inset-0 flex w-screen items-center justify-center py-4">
              {/* The actual dialog panel  */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-[90vw] w-[500px]  pb-4 rounded-md bg-white md:max-w-lg">
                  <Dialog.Title className="text-center relative font-bold text-BLACK leading-4 text-sm  py-4">
                    {!viewOnly ? (
                      <span className="block text-center font-neueMedium text-sm font-semibold leading-5 text-BLACK">
                        {edit ? "Edit Location Type" : "Add New Location Type"}
                      </span>
                    ) : (
                      <span>View Location Type</span>
                    )}
                    <span
                      onClick={close}
                      className={`${styles.iconWrapper} absolute top-3 right-3 cursor-pointer`}
                    >
                      <Icons.Cross />
                    </span>
                  </Dialog.Title>
                  <div className="h-px bg-LOCATION_CARD_BORDER w-full"></div>
                  <Dialog.Description className="mt-4">
                    <form className="px-4" onSubmit={(e) => e.preventDefault()}>
                      <div className="flex flex-col gap-2">
                        <label
                          className=" text-xs font-normal leading-4 text-BLACK md:text-sm"
                          htmlFor="title"
                        >
                          Location Type Title
                        </label>
                        {!viewOnly ? (
                          <input
                            className=" text-xs p-4 border border-LOCATION_CARD_BORDER rounded-lg focus:outline-none md:text-sm"
                            id="title"
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            placeholder="Enter Location Type Here"
                          />
                        ) : (
                          <div className=" px-2 py-4 text-sm md:text-base border border-LOCATION_CARD_BORDER rounded-lg text-GRAY font-medium cursor-pointer">
                            {locationType?.name}
                          </div>
                        )}
                        {formik.errors.title && formik.touched.title && (
                          <span className="text-xs text-red-500 font-medium md:text-sm">
                            {formik.errors.title}
                          </span>
                        )}
                      </div>
                      {!viewOnly ? (
                        <button
                          onClick={() => formik.handleSubmit()}
                          type="submit"
                          className=" mt-4 rounded-lg w-full text-center border-none py-4 bg-PRIMARY font-medium leading-5 text-white text-sm md:text-base hover:opacity-80 transition-all ease-linear duration-100"
                        >
                          {formik.isSubmitting ? (
                            <Spinner />
                          ) : edit ? (
                            "Update"
                          ) : (
                            "Add"
                          )}
                        </button>
                      ) : null}
                    </form>
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default LocationTypeModal;
