import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";

import ChartComp from "shared/components/chartComp";
import GuiderCard from "shared/components/guiderCard";
import CustomButton from "../../shared/components/customButton";
import StatsCard from "../../shared/components/statsCard";
import LocationCardSkeleton from "shared/skeletons/locationCardSkeleton";
import LocationCard from "shared/components/locationCard";
import GuiderCardSkeleton from "shared/skeletons/guiderCardSkeleton";

import { Icons } from "assets";
import {
  getGraphData,
  getMostVisitedLocations,
  getSortedUsers,
  getTotalStats,
} from "shared/services/adminService";

import styles from "./style.module.scss";
import { toastMessage } from "shared/components/toast";

const DashboradAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashboardStats, setDashboardStats] = useState<any>([
    {
      id: 1,
      title: "Total Users",
      value: null,
      icon: <Icons.UserIcon />,
      bg_color: "#F2F7FB",
    },
    {
      id: 3,
      title: "Blocked Users",
      value: null,
      icon: <Icons.GuidersIcon />,
      bg_color: "#FFF2ED",
    },
    {
      id: 2,
      title: "Locations",
      value: null,
      icon: <Icons.LocationIcon />,
      bg_color: "#E5FEF2",
    },
  ]);
  const [loader, setLoader] = useState<boolean>(true);
  const [locationLoader, setLocationLoader] = useState<boolean>(true);
  const [guidersLoader, setGuidersLoader] = useState<boolean>(true);
  const [locations, setLocation] = useState<any>([]);
  const [guiders, setGuiders] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Dashboard Admin",
            action: () => {
              navigate(routeConstant.dashboardAdmin.path);
            },
          },
        ],
      })
    );
  }, []);

  const getStats = () => {
    setLoader(true);
    getTotalStats()
      .then(({ status, data }) => {
        if (status) {
          const { users, locations, blockedUsers } = data;
          setDashboardStats((prevState: any) => {
            return prevState?.map((item: any) => {
              if (item?.id === 1) {
                return { ...item, value: users };
              } else if (item?.id === 2) {
                return { ...item, value: locations };
              } else if (item?.id === 3) {
                return { ...item, value: blockedUsers };
              }
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage("err", "Something went wrong!");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleGetGraphData = () => {
    getGraphData()
      .then(({ data, status }) => {
        if (status) {
          setGraphData(data);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        toastMessage("err", "Something went wrong!");
      });
  };

  const handleGetSortedGuiders = () => {
    getSortedUsers()
      .then(({ data, status }) => {
        if (status) {
          setGuiders(data);
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage("err", "Something went wrong!");
      })
      .finally(() => {
        setGuidersLoader(false);
      });
  };

  const handleGetVisitedLocations = () => {
    getMostVisitedLocations()
      .then(({ data, status }) => {
        if (status) {
          setLocation(data);
        }
      })
      .catch((err) => {
        console.log(err);
        toastMessage("err", "Something went wrong!");
      })
      .finally(() => {
        setLocationLoader(false);
      });
  };

  const handleViewAllClick = () => {
    navigate(routeConstant.locations.path);
  };

  useEffect(() => {
    getStats();
    handleGetGraphData();
    handleGetSortedGuiders();
    handleGetVisitedLocations();
  }, []);

  return (
    <div className="w-full">
      <h4 className="font-medium tracking-[0.12px] leading-8 text-xl mb-10 md:text-[28px]">
        Good Morning, Admin
      </h4>
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-480">
        {dashboardStats?.map((stat: any, inx: number) => {
          return (
            <React.Fragment key={inx}>
              <StatsCard loader={loader} stats={stat} />
            </React.Fragment>
          );
        })}
      </div>

      {/* Chart and Recently added users */}
      <div className="flex flex-col gap-4 mt-8 md:flex-row md:max-h-[450px] 2xl:h-[500px] 2xl:max-h-[500px]">
        <div className="h-full basis-full md:basis-7/12 lg:basis-8/12">
          <ChartComp graphData={graphData} />
        </div>
        {/* md:max-w-[268px] */}
        <div className="border basis-full max-w-full border-LOCATION_CARD_BORDER rounded-2xl md:basis-5/12 lg:basis-4/12 ">
          <p className="text-sm font-medium leading-5 py-4 px-3 font-neueMedium text-BLACK md:text-base">
            Recently Joined Guiders
          </p>
          <div>
            {guidersLoader ? (
              <GuiderCardSkeleton />
            ) : (
              guiders.slice(0, 4).map((guider: any, inx: number) => {
                const {
                  user: { _id, firstname, lastname, profile_picture_full_path },
                  count,
                } = guider;
                return (
                  <React.Fragment key={inx}>
                    <GuiderCard
                      id={_id}
                      locations={count}
                      image={profile_picture_full_path}
                      guiderName={firstname + " " + lastname}
                    />
                  </React.Fragment>
                );
              })
            )}
          </div>
          <Link
            to="/guiders"
            className="block font-normal text-[10px] text-center leading-4 text-SECONDARY py-4 underline hover:cursor-pointer md:text-xs"
          >
            View All Profiles
          </Link>
        </div>
      </div>

      {/* Locations Wrapper */}
      <div className={styles.locationsCardWrapper}>
        <div className="mt-8 flex items-center justify-between">
          <h1 className="text-lg text-BLACK font-medium leading-7 md:text-2xl">
            Most Visited Locations
          </h1>
          <CustomButton
            title="View All"
            containerStyle={styles.button}
            loading={false}
            onClick={handleViewAllClick}
            style={{
              backgroundColor: "#dc6d43",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "16px",
            }}
          />
        </div>

        <div className="grid grid-cols-1 gap-2 mt-10 md:grid-cols-3 xl:grid-cols-3 custom:grid-cols-1">
          {locationLoader ? (
            <LocationCardSkeleton />
          ) : (
            locations?.map((location: any, inx: number) => {
              const { _id, pictures, type, status, title } = location;
              return (
                <React.Fragment key={inx}>
                  <LocationCard
                    showFavourite={false}
                    status={status}
                    images={pictures}
                    gmu={type}
                    name={title}
                    id={_id}
                  ></LocationCard>
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboradAdmin;
