import React from "react";
import { Icons } from "assets";

import styles from "./style.module.scss";

interface CustomInputField {
  shouldColumnSpan?: any;
  htmlFor: string;
  labelText: string;
  type: string;
  placeHolder: string;
  setInputValue: any;
  value: string | any;
  icon?: any;
  onBlur: any;
  name: string;
  error: any;
  touched: any;
  passwordType?: boolean;
  showPassword?: any;
  required: boolean;
  setShowPassword?: any;
  disabled?: boolean;
}

const CustomInputField = ({
  htmlFor,
  icon,
  labelText,
  type,
  placeHolder,
  setInputValue,
  value,
  name,
  error,
  touched,
  onBlur,
  shouldColumnSpan,
  passwordType,
  showPassword,
  setShowPassword,
  required,
  disabled,
}: CustomInputField) => {
  return (
    <div className={`${shouldColumnSpan ? "col-span-2" : ""}`}>
      <label
        className="relative text-sm font-normal capitalize mb-[6px]"
        htmlFor={htmlFor}
      >
        {labelText}
        {required && (
          <div className="absolute text-red-500 text-sm top-0 -right-2">*</div>
        )}
      </label>
      <div
        className={`border border-LOCATION_CARD_BORDER rounded-lg flex items-center `}
      >
        {icon && <span className="pl-4">{icon}</span>}
        <input
          name={name}
          className={`text-sm font-normal w-full p-4 rounded-lg focus:outline-none ${styles.input}`}
          value={value}
          onChange={setInputValue}
          type={type}
          id={labelText}
          placeholder={placeHolder}
          onBlur={onBlur}
          disabled={disabled}
        />
        {passwordType && (
          <span
            className="hover:cursor-pointer mr-4 "
            onClick={() => setShowPassword((prevType: boolean) => !prevType)}
          >
            {!showPassword ? <Icons.ClosedEye /> : <Icons.Eye />}
          </span>
        )}
      </div>
      {error && touched && (
        <div className="pl-2 text-red-500 text-sm font-normal">
          {error + " *"}
        </div>
      )}
    </div>
  );
};

export default CustomInputField;
