import { Icons } from "assets";
import React from "react";
import styles from "./style.module.scss";

const NODataFound = ({ icon, text, detail }: any) => {
  return (
    <div className=" flex flex-col gap-2 justify-center items-center p-8">
      <div className=" w-32 h-32">
        <span className={`w-full h-full object-cover ${styles.iconWrapper}`}>
          {icon}
        </span>
      </div>
      <div className="flex flex-col text-center">
        <span className="text-base text-[#101828] font-medium leading-7 md:text-xl">
          {!text ? "No Data Found" : text}
        </span>
        <span className="text-base max-w-68 text-[#667085] font-normal md:text-lg">
          {detail ? detail : null}
        </span>
      </div>
    </div>
  );
};

export default NODataFound;
