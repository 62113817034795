import { EndPoints, OpenURL } from "shared/utils/endPoints";
import { HTTP_CLIENT } from "../utils/interceptor";
import axios from "axios";
import { store } from "shared/redux/store/store";



const getSearchItems = (resp: { search: string, activePage: number, itemsPerPage: number }) => {
  const params = new URLSearchParams();

  if (resp.search) params.append('search', resp.search.toString());
  if (resp.activePage) params.append('pageNo', resp.activePage.toString());
  if (resp.itemsPerPage) params.append('perPage', resp.itemsPerPage.toString());
  return axios.get(`${OpenURL}locations/search-locations?${params}`,);
}

const updateProfile = (resp: any) => {
  return HTTP_CLIENT.put(EndPoints.profile.updateProfile, resp);
};

const updatePassword = (resp: any) => {
  return HTTP_CLIENT.post(EndPoints.profile.changePassword, resp);
};

const getTotalStats = () => {
  return HTTP_CLIENT.get(`${EndPoints.admin.getTotalCount}`);
};

const getAllUsers = (resp = { search: "", activePage: 1, itemsPerPage: 5, sort: '' }) => {
  const params = new URLSearchParams();
  if (resp?.search) params.append("search", resp?.search);
  if (resp?.itemsPerPage)
    params.append("perPage", resp?.itemsPerPage.toString());
  if (resp?.activePage) params.append("pageNo", resp?.activePage.toString());
  if (resp?.sort) params.append("status", resp?.sort?.toString());
  return HTTP_CLIENT.get(`${EndPoints.admin.getAllUsers}?${params}`);
};

const activateUser = (id: any) => {
  return HTTP_CLIENT.post(`${EndPoints.admin.activateUser}/${id}`);
};

const deactivateUser = (id: any) => {
  return HTTP_CLIENT.post(`${EndPoints.admin.deactivateUser}/${id}`);
};

const changeUserStats = (id: any) => {
  return HTTP_CLIENT.post(`${EndPoints.admin.changeUserStatus}/${id}`);
}

const getUserDetails = (id: any) => {
  return HTTP_CLIENT.get(`${EndPoints.admin.getUserDetails}/${id}`);
};

const getSingleUserLocations = (resp: { id: any, pageNo: number, perPage: number | null }) => {
  const params = new URLSearchParams();
  if (resp.pageNo) params.append('pageNo', resp.pageNo.toString());
  if (resp.perPage) params.append('perPage', resp.perPage.toString());
  return HTTP_CLIENT.get(`${EndPoints.admin.getSingleUserLocation}/${resp.id}?${params}`);
};

const getAllLocations = (
  resp = { itemsPerPage: 1, activePage: 1, sort: '', search: "" }
) => {
  const params = new URLSearchParams();
  if (resp?.search) params.append("search", resp?.search);
  if (resp?.itemsPerPage)
    params.append("perPage", resp?.itemsPerPage.toString());
  if (resp?.activePage) params.append("pageNo", resp?.activePage.toString());
  if (resp?.sort) params.append("status", resp?.sort.toString());
  return HTTP_CLIENT.get(
    `${EndPoints.admin.getUserLocations}?${params.toString()}`
  );
};

const getRequestedLocations = (
  resp = { search: "", activePage: 1, itemsPerPage: 5 }
) => {
  const params = new URLSearchParams();
  if (resp?.search) params.append("search", resp?.search);
  if (resp?.itemsPerPage)
    params.append("perPage", resp?.itemsPerPage.toString());
  if (resp?.activePage) params.append("pageNo", resp?.activePage.toString());
  return HTTP_CLIENT.get(
    `${EndPoints.admin.getRequestedLocation}?${params.toString()}`
  );
};

const approveRequestedLocation = (id: any) => {
  return HTTP_CLIENT.post(`${EndPoints.admin.approveRequestedLocation}/${id}`);
};

const rejectRequestedLocation = (id: any) => {
  return HTTP_CLIENT.post(`${EndPoints.admin.rejectRequestedLocation}/${id}`);
};

const getGraphData = () => {
  return HTTP_CLIENT.get(EndPoints.admin.graphData);
};

const getSortedUsers = () => {
  return HTTP_CLIENT.get(EndPoints.admin.dashboardUsers);
};

const getMostVisitedLocations = () => {
  return HTTP_CLIENT.get(EndPoints.admin.visitedLocations);
};

const getLocationDetails = (id: any) => {
  return HTTP_CLIENT.get(`${EndPoints.admin.getLocationDetails}/${id}`)
}

// Get Amenities for Admin 

const getAmenities = (resp: { search: string, filter: number }) => {
  const params = new URLSearchParams();
  if (resp?.search) params.append('search', resp?.search);
  if (resp?.filter) params.append("status", resp?.filter.toString());
  const url = `${OpenURL}amenities/getAmenities?${params}`;
  return axios.get(url);
}

const addAmenity = (formData: FormData) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}amenities/add`;
  const headers = {
    Authorization: `Bearer ${token}`,
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, formData, { headers });
}

const editAmenity = (formData: FormData, id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}amenities/edit/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, formData, { headers });
}


const deleteAmenity = (id: any) => {
  const params = new URLSearchParams();
  if (id) params.append('id', id.toString());
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}amenities/delete`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json', // Optional: Set the content type if needed
  };
  return axios.post(url, { id: id }, { headers });

}

// Reviews Services 

const getAllReviews = (resp: { status: number, search: string, activePage: number, itemsPerPage: number }) => {
  const params = new URLSearchParams();
  if (resp?.status) params.append('status', resp.status.toString());
  if (resp.search) params.append('search', resp.search);
  if (resp.activePage) params.append('pageNo', resp.activePage.toString());
  if (resp.itemsPerPage) params.append('perPage', resp.itemsPerPage.toString());
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}testimonial/get/?${params}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json', // Optional: Set the content type if needed
  };

  return axios.get(url, { headers });

}

const deleteReview = (id: any) => {
  const params = new URLSearchParams();
  if (id) params.append('id', id.toString());
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}testimonial/delete`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json', // Optional: Set the content type if needed
  };
  return axios.post(url, { id: id }, { headers });
}

const addReview = (formData: FormData) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}testimonial/add`;
  const headers = {
    Authorization: `Bearer ${token}`,
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, formData, { headers });
}

const editReview = (formData: FormData, id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}testimonial/edit/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, formData, { headers });
}


// Reports

const reportedUsers = (resp: { status: number, search: string, activePage: number, itemsPerPage: number }) => {
  const params = new URLSearchParams();
  if (resp.status) params.append('status', resp?.status?.toString());
  if (resp.search) params.append('search', resp.search);
  if (resp.activePage) params.append('pageNo', resp.activePage.toString());
  if (resp.itemsPerPage) params.append('perPage', resp.itemsPerPage.toString());
  return HTTP_CLIENT.get(`${EndPoints.admin.reportedUsers}/?${params}`);
}

// Location and Service Types

const getLocationTypes = (resp: { search: string }) => {
  const params = new URLSearchParams();
  if (resp.search) params.append('search', resp?.search.toString());
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}locationTypes/get/?${params}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    // Do not set Content-Type here for FormData
  };
  return axios.get(url, { headers });

}

const addLocationType = (locationTypeName: string) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}locationTypes/add`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, { name: locationTypeName }, { headers });
}



const editLocationType = (locationTypeName: string, id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}locationTypes/edit/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, { name: locationTypeName }, { headers });
}

const deleteLocationType = (id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}locationTypes/delete/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, {}, { headers });
}

// SERVICES TYPES 

const getServicesTypes = (resp: { search: string }) => {
  const params = new URLSearchParams();
  if (resp.search) params.append('search', resp?.search.toString());
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}servicesTypes/get/?${params}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.get(url, { headers });

}

const addServiceType = (locationTypeName: string) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}servicesTypes/add`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, { name: locationTypeName }, { headers });
}

const editServiceType = (locationTypeName: string, id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}servicesTypes/edit/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, { name: locationTypeName }, { headers });
}

const deleteServiceType = (id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}servicesTypes/delete/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, {}, { headers });
}

// SUPPORT TICKETS

const getAllContactsMessages = (resp: { search: string, activePage: number, itemsPerPage: number }) => {
  const params = new URLSearchParams();
  if (resp.search) params.append('search', resp.search);
  if (resp.activePage) params.append('pageNo', resp.activePage.toString());
  if (resp.itemsPerPage) params.append('perPage', resp.itemsPerPage.toString());

  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}support/getTickets/?${params}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };

  return axios.get(url, { headers });
}

const deleteSupportTicket = (id: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}support/deleteSupport/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.post(url, {}, { headers });
}

// Terms And Privacy Policy 

const getTermsAndConditions = () => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}terms/getTerms`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.get(url, { headers });
}

const editTermsAndConditions = (formBody: any) => {
  // terms/editTerms
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}terms/editTerms`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, formBody, { headers });

}

const getPrivacyPolicy = () => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}policy/getPolicy`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.get(url, { headers });
}

const editPrivacyPolicy = (formBody: any) => {
  const token = store.getState()?.root?.userSlice?.token;
  const url = `${OpenURL}policy/editPolicy`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // Do not set Content-Type here for FormData
  };
  return axios.put(url, formBody, { headers });

}

export {
  updateProfile,
  getAllUsers,
  getAllLocations,
  getRequestedLocations,
  approveRequestedLocation,
  rejectRequestedLocation,
  getTotalStats,
  activateUser,
  deactivateUser,
  getUserDetails,
  getSingleUserLocations,
  getGraphData,
  getSortedUsers,
  getMostVisitedLocations,
  updatePassword,
  getLocationDetails,
  getSearchItems,
  getAmenities,
  addAmenity,
  deleteAmenity,
  getAllReviews,
  editAmenity,
  changeUserStats,
  deleteReview,
  reportedUsers,
  addReview,
  editReview,
  getLocationTypes,
  addLocationType,
  getServicesTypes,
  editLocationType,
  deleteLocationType,
  addServiceType,
  editServiceType,
  deleteServiceType,
  getAllContactsMessages,
  deleteSupportTicket,
  getTermsAndConditions,
  editTermsAndConditions,
  getPrivacyPolicy,
  editPrivacyPolicy
};
