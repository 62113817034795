import React, { useEffect, useState } from "react";

import { columnHeadings } from "./constants";
import { getLocationTypes } from "shared/services/adminService";
import styles from "./style.module.scss";
import Pagination from "../pagination";
import CustomTooltip from "../toolTip";
import { Icons } from "assets/icons";
import LocationTypeModal from "shared/modals/locationTypeModal";
import TypesSkeleton from "shared/skeletons/typesSkeleton";
import { deleteLocationType } from "shared/services/adminService";
import { toastMessage } from "../toast";
import Confirmation from "shared/modals/confirmation";

const LocationServiceType = ({
  openLocationTypeModal,
  setOpenLocationTypeModal,
  searchText,
}: any) => {
  const [locationTypes, setLocationTypes] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [locationType, setLocationType] = useState<any>({});
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [viewOnly, setViewOnly] = useState<boolean>(false);

  const handleView = (type: any) => {
    setViewOnly(true);
    setLocationType(type);
    setOpenLocationTypeModal(true);
  };

  const handleEdit = (type: any) => {
    setIsEdit(true);
    setLocationType(type);
    setOpenLocationTypeModal(true);
  };

  const handleDelete = (type: any) => {
    setLocationType(type);
    setOpenConfirmationModal(true);
  };

  const confirmDelete = () => {
    setLoader(true);
    deleteLocationType(locationType?._id)
      .then(({ status, data }) => {
        if (status) {
          toastMessage("success", data);
          setOpenConfirmationModal(false);
          getAllLocationTypes();
        }
      })
      .catch((err) => {
        console.log("Err---", err);
        toastMessage("err", "Something went wrong!");
      });
  };

  const getAllLocationTypes = () => {
    setLoader(true);
    getLocationTypes({ search: searchText })
      .then(({ status, data }) => {
        if (status) {
          const { count, types } = data;
          setLocationTypes(types);
          setTotalPages(count);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
      });
  };

  useEffect(() => {
    getAllLocationTypes();
  }, [searchText]);

  return (
    <section>
      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            {locationTypes?.length > 0 && (
              <thead className="text-xs text-gray-700 capitalize bg-gray-50  ">
                <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                  {columnHeadings.map((column: any) => {
                    return (
                      <th
                        key={column.id}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-GRAY"
                      >
                        {column.value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {loader ? (
                Array.from({ length: 6 }).map((_, index) => {
                  return <TypesSkeleton actions={3} />;
                })
              ) : (
                <>
                  {locationTypes.length
                    ? locationTypes?.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className=" bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER"
                          >
                            <td className="px-6 py-4 ">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.name ? item?.name : "Not Provided"}
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <span
                                  onClick={() => handleView(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"View"}
                                    icon={<Icons.ViewIcon />}
                                    index={1}
                                  />
                                </span>
                                <span
                                  onClick={() => handleEdit(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"Edit"}
                                    icon={<Icons.Pencil />}
                                    index={2}
                                  />
                                </span>
                                <span
                                  onClick={() => handleDelete(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"Delete"}
                                    icon={<Icons.Delete />}
                                    index={3}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {locationTypes?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>

      {/* Add / Edit New Location Type */}
      {openLocationTypeModal && (
        <LocationTypeModal
          viewOnly={viewOnly}
          getAllLocationTypes={getAllLocationTypes}
          locationType={isEdit || viewOnly ? locationType : null}
          edit={isEdit ? isEdit : false}
          open={openLocationTypeModal}
          close={() => {
            setViewOnly(false);
            setIsEdit(false);
            setOpenLocationTypeModal(false);
          }}
        />
      )}
      {/* Confirmation */}
      <Confirmation
        close={() => setOpenConfirmationModal(false)}
        text="Are you sure you want to delete this location type ?"
        loader={loader}
        isOpen={openConfirmationModal}
        handleConfirm={confirmDelete}
        subText="Clicking on the confirm button will permanently delete the location type"
      />
    </section>
  );
};

export default LocationServiceType;
