import AuthAsset1 from "./auth-asset-1.png";
import BlankProfile from "./blank.webp";
import BlankUserPlaceHolder from "./userPlaceholder.png";


const Images = {
    AuthAsset1,
    BlankProfile,
    BlankUserPlaceHolder
}

export { Images };