import { Icons, Images } from "assets";
import { useFormik } from "formik";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./style.module.scss";
import CustomInput from "shared/components/customInput";
import CustomButton from "shared/components/customButton";
import { routeConstant } from "shared/routes/routesConstant";
import { LoginVS } from "shared/utils/validation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { login } from "shared/services/authService";
import { useDispatch } from "react-redux";
import { setUser } from "shared/redux/reducers/userSlice";
import { toastMessage } from "shared/components/toast";

const initialValues: LoginType = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: LoginVS,
    onSubmit: (value, action) => {
      action.setSubmitting(false);
      handleLogin();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = formik;

  const handleLogin = () => {
    setSubmitting(true);
    let payload = {
      email: values.email,
      password: values.password,
    };
    login(payload)
      .then(({ data, status }) => {
        if (status) {
          dispatch(
            setUser({
              user: data?.user,
              isLoggedIn: true,
              token: data?.token,
            })
          );
          toastMessage("success", "Logged In Successfully");
        }
      })
      .catch((err) => {
        toastMessage("err", err?.response?.data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <main
      className={classNames("flex items-center")}
      style={{ height: "100vh" }}
    >
      <section
        className={classNames(
          "w-full lg:w-1/2 flex flex-col items-center  relative",
          styles.leftContainer
        )}
      >
        <Link
          to={routeConstant.auth.path}
          className={classNames(styles.logoContainer)}
        >
          <Icons.Logo />
        </Link>

        <form
          className={classNames(styles.formContainer)}
          onSubmit={handleSubmit}
        >
          <div className={classNames("flex flex-col items-center gap-3")}>
            <h1 className={classNames(styles.title)}>
              Welcome to Guide Connect
            </h1>
            <p className={classNames(styles.subTitle)}>
              Please log in to access exclusive features and make the most of
              your stay.
            </p>
          </div>
          <div className={classNames("flex flex-col gap-3")}>
            <CustomInput
              label="Business Email"
              placeholder="Enter your business email"
              required
              Icon={Icons.Mail}
              type="email"
              value={values.email}
              error={touched.email && errors.email ? errors.email : ""}
              onChange={handleChange("email")}
            />
            <CustomInput
              label="Password"
              placeholder="Password"
              required
              isPassword
              Icon={Icons.Lock}
              type="password"
              value={values.password}
              error={touched.password && errors.password ? errors.password : ""}
              onChange={handleChange("password")}
            />
            {/* <label className={classNames(styles.forgotLabel)}>
              Forgot password?
            </label> */}
          </div>
          <div className={classNames("flex flex-col items-center gap-3")}>
            <CustomButton
              title="Log In"
              type="Submit"
              style={{ width: "100%" }}
              onClick={() => handleSubmit()}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </section>
      <section className={classNames("w-1/2 hidden lg:flex relative")}>
        <Swiper
          slidesPerView={"auto"}
          // centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          onRealIndexChange={(element) => {
            setActiveIndex(element.activeIndex);
          }}
        >
          {Array.from(Array(6).keys()).map((itm, inx) => {
            return (
              <SwiperSlide key={inx}>
                <img
                  src={Images.AuthAsset1}
                  alt=""
                  className={classNames(styles.assetImg)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={classNames(styles.dotsContainer)}>
          {Array.from(Array(6).keys()).map((itm, inx) => {
            return (
              <div
                className={classNames(
                  styles.dot,
                  activeIndex === inx && styles.active
                )}
                key={inx}
              />
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default Login;
