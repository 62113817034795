import React from "react";

import { Icons, Images } from "assets";
import GeneralSkeleton from "shared/skeletons/generalSkeleton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Spinner from "../spinner";

const GuiderProfileCrad = ({ user, loader, count }: any) => {
  const navigate = useNavigate();

  const handleViewProfile = () => {
    navigate(routeConstant.guide_detail.path.replace(":id", user?._id));
  };

  return (
    <div className="p-4 bg-white border border-LOCATION_CARD_BORDER rounded-lg">
      <div className="flex flex-col items-center space-y-3">
        <div className=" w-24 h-24 md:w-[110px] md:h-[110px]">
          <img
            className="w-full h-full object-cover rounded-full"
            src={
              user?.profile_picture_full_path
                ? user?.profile_picture_full_path
                : Images.BlankUserPlaceHolder
            }
            alt="person Image"
            loading="lazy"
          ></img>
        </div>
        <div className="text-sm text-BLACK font-medium md:text-base">
          {loader ? (
            <GeneralSkeleton className="h-4 bg-gray-200 rounded-full w-20" />
          ) : (
            `${user?.firstname ? user?.firstname : ""} ${
              user?.lastname ? user?.lastname : ""
            }`
          )}
        </div>
        {/* Email */}
        <div className="flex gap-2 items-center">
          <span>
            <Icons.Mail2 />
          </span>
          <span className="text-sm text-GRAY md:text-base">
            {loader ? (
              <GeneralSkeleton className="h-3 bg-gray-200 rounded-full w-32" />
            ) : user?.email ? (
              <a href={`mailto:${user?.email}`}>{user?.email}</a>
            ) : (
              "Not Provided"
            )}
          </span>
        </div>
        {/* Phone */}
        <div className="flex gap-2 items-center">
          <span>
            <Icons.Phone />
          </span>
          <span className="text-sm text-GRAY md:text-base">
            {loader ? (
              <GeneralSkeleton className="h-3 bg-gray-200 rounded-full w-32" />
            ) : user?.phone ? (
              <a href={`tel:${user.phone}`}>{user?.phone}</a>
            ) : (
              "Not Provided"
            )}
          </span>
        </div>
        <hr className="border border-GRAY w-full opacity-30" />
        <div className="flex text-[10px] text-GRAY gap-[6px] md:text-base">
          <span>Total Services Locations:</span>
          <span className="text-BLACK">
            {loader ? (
              <GeneralSkeleton className="h-4 bg-gray-200 rounded-sm w-4" />
            ) : (
              count
            )}
          </span>
        </div>
        <button
          className=" text-sm py-[10px] w-full rounded-lg bg-PRIMARY text-white hover:opacity-80 md:text-base"
          onClick={handleViewProfile}
        >
          {loader ? <Spinner /> : "View Profile"}
        </button>
      </div>
    </div>
  );
};

export default GuiderProfileCrad;
