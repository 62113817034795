import { EndPoints } from "shared/utils/endPoints";
import { HTTP_CLIENT } from "../utils/interceptor";


const login = (resp: {}) => {
    return HTTP_CLIENT.post(EndPoints.auth.login, resp);
}

export {
    login,
}