import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import GuiderContactCard from "shared/components/guiderContactCard";
import GuiderDetailsCard from "shared/components/guiderDetailCard";
import LocationCard from "shared/components/locationCard";
import LocationCardSkeleton from "shared/skeletons/locationCardSkeleton";
import {
  getUserDetails,
  getSingleUserLocations,
} from "shared/services/adminService";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import { toastMessage } from "shared/components/toast";

import { Icons } from "assets";
import styles from "./styles.module.scss";

const GuideDetail = () => {
  const { id: userId } = useParams();
  const [endReach, onScroll] = useOnScroll("locations-wrapper");
  const [loader, setLoader] = useState<boolean>(false);
  const [userDetailsLoader, setUserDetailsLoader] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useState<any>({});
  const [locations, setLocations] = useState<any>([]);
  const [moreLoad, setMoreLoad] = useState<boolean>(true);

  const pageRef = useRef<number>(1);
  const locationsRef = useRef<any[]>(locations ? locations : []);

  const getUserDetail = (userId: any) => {
    setLoader(true);
    getUserDetails(userId)
      .then(({ status, data }) => {
        if (status) {
          setUserDetail(data);
          setUserDetailsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setUserDetailsLoader(false);
        toastMessage("Err", "Something Went Wrong");
      });
  };

  const getLocations = (userId: any) => {
    setLoader(true);
    getSingleUserLocations({
      id: userId,
      pageNo: pageRef.current,
      perPage: 5,
    })
      .then(({ status, data }) => {
        if (status) {
          let temp = [...locationsRef.current, ...data];
          setLoader(false);
          setLocations(temp);
          locationsRef.current = temp;
          if (data?.length === 0) {
            setMoreLoad(false);
          } else {
            setMoreLoad(true);
          }
        }
      })
      .catch((err) => {
        console.log("err--", err);
        setLoader(false);
        toastMessage("error", "Soething went wrong!");
      });
  };

  useEffect(() => {
    getUserDetail(userId);
    getLocations(userId);
  }, []);

  useEffect(() => {
    if (endReach && !loader && moreLoad) {
      pageRef.current++;
      setLoader(true);
      getLocations(userId);
    }
  }, [endReach]);

  return (
    <div
      onScroll={onScroll}
      id="locations-wrapper"
      className={` ${styles.container} flex flex-col md:flex-row gap-0 md:gap-5`}
    >
      <div className="flex flex-col gap-8 md:col-span-1 md:min-w-[270px] lg:min-w-[300px]">
        <GuiderDetailsCard
          loader={userDetailsLoader}
          userDetail={userDetail}
          setUserDetail={setUserDetail}
        />
        <GuiderContactCard userDetail={userDetail} loader={userDetailsLoader} />
      </div>
      <div className="w-full">
        <div className="text-[32px] text-BLACK font-medium mt-4 md:mt-0 mb-8">
          Locations
        </div>
        <div
          className={`  grid grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-5 custom:grid-cols-1`}
        >
          {locations.length > 0 &&
            locations?.map((location: any) => {
              const { _id, pictures, status, title, type } = location;
              return (
                <React.Fragment key={_id}>
                  <LocationCard
                    id={_id}
                    showFavourite={false}
                    status={status}
                    images={pictures}
                    gmu={type}
                    name={title}
                  />
                </React.Fragment>
              );
            })}
          {/*  <LocationCardSkeleton /> */}
          {loader ? <LocationCardSkeleton /> : null}
        </div>

        {locations.length === 0 && !loader ? (
          <div
            className={`mt-8 flex justify-center items-center ${styles.iconWrapper}`}
          >
            <span>
              <Icons.NoLocation />
              <div className="font-bold text-base text-center">
                No Location Found
              </div>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GuideDetail;
