import React, { useEffect, useRef, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";

import CustomInputField from "../customInputField";

import { accountInfoValidationSchema } from "shared/utils/validation";

import { Icons, Images } from "assets";

import "react-phone-number-input/style.css";
import styles from "./style.module.scss";
import { updateProfile } from "shared/services/adminService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser } from "shared/redux/reducers/userSlice";
import { toastMessage } from "../toast";
import Spinner from "../spinner";

const AccountInfo = () => {
  const { user } = useSelector((state: any) => state.root.userSlice);
  const dispatch = useDispatch();
  const fileInputRef = useRef<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>(
    user?.phone ? user?.phone : ""
  );
  const [file, setFile] = useState<any>(null);

  const initialValues = {
    firstName: user?.firstname ? user?.firstname : "",
    lastName: user?.lastname ? user?.lastname : "",
    email: user?.email ? user?.email : "",
    phoneNumber: user?.phone ? user?.phone : "",
    address: user?.address ? user?.address : "",
    image: user?.profile_picture_full_path
      ? user?.profile_picture_full_path
      : null,
  };

  const handleUpdateProfile = () => {
    formik.setSubmitting(true);
    let payload: any = {
      firstname: formik.values.firstName,
      lastname: formik.values.lastName,
      phone: formik.values.phoneNumber,
      address: formik.values.address,
    };
    let formBody = new FormData();

    Object.keys(payload).forEach((key: any) => {
      formBody.append(key, payload[key]);
    });
    if (file) {
      formBody.append("profile_picture", file);
    } else {
      formBody.append("profile_picture", formik.values.image);
    }
    // RESET FORM VALUES ALSO
    updateProfile(formBody)
      .then(({ data, status }) => {
        if (status) {
          dispatch(
            setUser({
              user: { ...data },
            })
          );
          toastMessage("success", "Profile Updated Successfully");
        }
      })
      .catch((err: any) => {
        toastMessage("error", err);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const handleBlur = () => {
    formik.setFieldValue("phoneNumber", phoneNumber);
    formik.setFieldTouched("phoneNumber", true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: accountInfoValidationSchema, // Set Yup schema
    onSubmit: () => {
      handleUpdateProfile();
    },
  });

  useEffect(() => {
    // Use setFieldValue to update the value of yourFormikField
    formik.setFieldValue("phoneNumber", phoneNumber);
  }, [phoneNumber]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <h1 className=" font-bold text-lg text-BLACK mb-8">
        Account Information
      </h1>
      <div className="mb-10">
        <label className="font-normal text-sm text-BLACK">
          Upload Profile Photo
        </label>
        <div className="relative w-[100px] h-[100px] mx-auto">
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : formik.values.image
                ? formik.values.image
                : Images.BlankProfile
            }
            className="w-full h-full object-cover rounded-full"
          />
          <div className="absolute bottom-0 right-0 flex justify-center items-center p-[10px] bg-SECONDARY z-10 rounded-full hover:cursor-pointer">
            <label className="hover:cursor-pointer" htmlFor="profile-image">
              <Icons.UploadPhoto />
              <input
                type="file"
                onBlur={formik.handleBlur}
                id="profile-image"
                name="profile-image"
                style={{ display: "none" }}
                onChange={(event: any) => {
                  setFile(event?.currentTarget?.files[0]);
                  formik.setFieldValue("image", event?.currentTarget?.files[0]);
                }}
                ref={fileInputRef}
              />
              {formik.errors.image && formik.touched.image ? (
                <p className="font-sm text-red-500 absolute top-[1rem] -right-[9rem]">
                  {formik.errors.image + " *"}
                </p>
              ) : null}
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5  md:grid md:gap-5  md:grid-cols-2">
        <CustomInputField
          required={true}
          error={formik?.errors?.firstName}
          touched={formik?.touched?.firstName}
          name="firstName"
          htmlFor="firstName"
          placeHolder="Enter First Name Here"
          value={formik.values.firstName}
          setInputValue={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          labelText="First Name"
        />
        <CustomInputField
          required={true}
          error={formik?.errors?.lastName}
          touched={formik?.touched?.lastName}
          name="lastName"
          htmlFor="lastName"
          placeHolder="Enter Last Name Here"
          value={formik.values.lastName}
          setInputValue={formik.handleChange}
          type="text"
          onBlur={formik.handleBlur}
          labelText="Last Name"
        />

        {/* React Phone Input  */}
        <div className="phone-input-wrapper">
          <label className="text-sm font-medium" htmlFor="phoneNumber">
            Phone Number
          </label>
          <div className={styles.phoneInputContainer}>
            <PhoneInput
              onBlur={handleBlur}
              className={`border px-4 h-[54px] border-LOCATION_CARD_BORDER rounded-lg  ${styles.phoneInput}`}
              name="phoneNumber"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
          {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
            <p className="pl-2 text-red-500 text-sm font-normal">
              {formik.errors.phoneNumber + " *"}
            </p>
          ) : null}
        </div>
        {/* End */}

        <CustomInputField
          required={true}
          error={formik?.errors?.email}
          touched={formik?.touched?.email}
          name="email"
          htmlFor="email"
          placeHolder="Enter Email Here"
          value={formik.values.email}
          setInputValue={formik.handleChange}
          onBlur={formik.handleBlur}
          labelText="Email"
          type="email"
          icon={<Icons.Mail />}
          disabled
        />
        <CustomInputField
          required
          shouldColumnSpan={true}
          error={formik?.errors?.address}
          touched={formik?.touched?.address}
          name="address"
          htmlFor="address"
          placeHolder="Enter Address Here"
          value={formik.values.address}
          setInputValue={formik.handleChange}
          onBlur={formik.handleBlur}
          labelText="Address Location"
          type="text"
          icon={<Icons.AddressIcon />}
        />
      </div>

      <hr className="w-full border mt-8 border-LOCATION_CARD_BORDER" />
      <div className="flex justify-center md:justify-end">
        <button
          onClick={() => formik.handleSubmit()}
          type="submit"
          className="py-4 px-12 bg-PRIMARY rounded-lg text-white mt-10 hover:opacity-80 transition-all ease-linear delay-150"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? <Spinner /> : "Save Changes"}
        </button>
      </div>
    </form>
  );
};

export default AccountInfo;
