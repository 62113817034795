import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  user: Record<string, any>;
  isLoggedIn: boolean;
  token: string | null;
}

const initialState: UserState = {
  user: {},
  isLoggedIn: false,
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    resetUser: () => initialState,
  },
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
