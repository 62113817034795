import React from "react";

import { Link, useNavigate } from "react-router-dom";

import HeaderDropDown from "../headerDropDownMenu";
import BreadCrumb from "../breadCrumbs";

import { routeConstant } from "shared/routes/routesConstant";
import { Icons } from "assets";

import styles from "./style.module.scss";
import classNames from "classnames";
import SearchComponent from "../globalSearch";

interface HeaderProps {
  setMenuToggle: any;
}

const Header = ({ setMenuToggle }: HeaderProps) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      menuText: "Account Settings",
      link: routeConstant.settings.path,
      action: () => navigate(routeConstant.settings.path),
    },
  ];

  return (
    <header className={classNames(styles.header)}>
      <div className="flex gap-2 items-center">
        <div
          onClick={() => setMenuToggle(true)}
          className="flex items-center justify-center w-8 h-8 hamburger:hidden hover:cursor-pointer"
        >
          <Icons.MenuIcon />
        </div>
        <div className="flex items-center justify-between w-full">
          <div>
            <BreadCrumb />
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden md:block">
              <SearchComponent />
            </div>
            <div className="flex gap-[10px] items-start pb-0 hover:cursor-pointer ">
              <HeaderDropDown menuItems={menuItems} />
            </div>
          </div>
        </div>
      </div>
      <div className="block mt-2 md:hidden ">
        <SearchComponent />
      </div>
    </header>
  );
};

export default Header;
