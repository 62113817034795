import React, { useEffect, useState } from "react";

import CustomTableHeader from "shared/components/customTableHeader";
import NODataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import moment from "moment";

import { Icons, Images } from "assets";
import { columnHeadings, sortingOptions } from "./constants";
import { getAllReviews } from "shared/services/adminService";
import styles from "./style.module.scss";
import { toastMessage } from "shared/components/toast";
import CustomTooltip from "shared/components/toolTip";
import { deleteReview } from "shared/services/adminService";

import ReviewsTableSkeleton from "shared/skeletons/reviewsTableSkeleton";
import Confirmation from "shared/modals/confirmation";
import ReviewModal from "shared/modals/reviewModal";
import useDebounce from "shared/customHooks/useDebounce";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

const Reviews = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<number>(-1);
  const [activePage, setActivePage] = useState<number>(1);
  const [openAddReviewModal, setOpenAddReviewModal] = useState<boolean>(false);
  const [reviews, setReviews] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [review, setReview] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewOnly, setViewOnly] = useState<boolean>(false);
  const totalStars = 5;

  const handleView = (review: any) => {
    setIsEdit(false);
    setViewOnly(true);
    setOpenAddReviewModal(true);
    setReview(review);
  };

  const handleEdit = (review: any) => {
    setViewOnly(false);
    setReview(review);
    setOpenAddReviewModal(true);
    setIsEdit(true);
  };

  const handleDelete = (review: any) => {
    setReview(review);
    setOpenDeleteModal(true);
  };

  const deleteSelectedReview = () => {
    setLoader(true);
    deleteReview(review?._id)
      .then(({ status, data }) => {
        if (status) {
          toastMessage("success", data);
          getReviews();
          setOpenDeleteModal(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error---", err);
        toastMessage("error", "Something went wrong!");
        setOpenDeleteModal(false);
      });
  };

  const getReviews = () => {
    setLoader(true);
    getAllReviews({
      status: sort,
      search: searchText,
      activePage: activePage,
      itemsPerPage: 10,
    })
      .then(({ status, data }) => {
        const { testimonials, count } = data;
        if (status) {
          setLoader(false);
          setReviews(testimonials);
          // Change this
          setTotalPages(count);
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
        toastMessage("error", "Something went wrong!");
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Reviews",
            action: () => {
              navigate(routeConstant.reviews.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getReviews();
  }, [sort, searchText, activePage]);

  return (
    <section>
      <div>
        <CustomTableHeader
          heading="All Reviews"
          subHeading="Showing the list of all reviews"
          showFilter={true}
          setSearch={setSearch}
          search={search}
          setSort={setSort}
          sortingOptions={sortingOptions}
          showModalButton={true}
          setActivePage={setActivePage}
          sortText={sort}
          modalButtonText="Add New Review"
          setOpenModal={() => {
            setOpenAddReviewModal(true);
            setIsEdit(false);
            setViewOnly(false);
          }}
        />
      </div>
      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            {reviews?.length > 0 && (
              <thead className="text-xs text-gray-700 capitalize bg-gray-50  ">
                <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                  {columnHeadings.map((column: any) => {
                    return (
                      <th
                        key={column.id}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-GRAY"
                      >
                        {column.value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {loader ? (
                Array.from({ length: 8 }).map((_, index) => {
                  return <ReviewsTableSkeleton key={index} actions={3} />;
                })
              ) : (
                <>
                  {reviews.length > 0
                    ? reviews?.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY    border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className=" px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="max-w-10 min-w-10 h-10 rounded-full overflow-hidden">
                                  <img
                                    alt={item?.name}
                                    loading="lazy"
                                    className="w-full h-full rounded-lg object-cover aspect-[1]"
                                    src={
                                      item?.picture ? (
                                        item?.picture
                                      ) : (
                                        <Images.BlankUserPlaceHolder />
                                      )
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.name ? item?.name : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex gap-1">
                                {Array.from({ length: totalStars }).map(
                                  (_, index) => {
                                    if (index < item?.ratings) {
                                      return (
                                        <svg
                                          key={index}
                                          className="w-4 h-4 text-SECONDARY me-1"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="currentColor"
                                          viewBox="0 0 22 20"
                                        >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                        </svg>
                                      );
                                    } else
                                      return (
                                        <svg
                                          key={index}
                                          className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="currentColor"
                                          viewBox="0 0 22 20"
                                        >
                                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                        </svg>
                                      );
                                  }
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4 font-medium text-xs md:text-sm text-GRAY leading-5">
                              {item?.createdAt
                                ? moment(item?.createdAt).format("DD-MM-YYYY")
                                : "Not Provided"}
                            </td>

                            <td className="px-6 py-4 min-w-72 font-medium text-xs md:text-sm text-GRAY leading-5">
                              {item?.message
                                ? `${item?.message.slice(0, 40)}...`
                                : "Not Provided"}
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <span
                                  onClick={() => handleView(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"View"}
                                    icon={<Icons.ViewIcon />}
                                    index={1}
                                  />
                                </span>
                                <span
                                  onClick={() => handleEdit(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"Edit"}
                                    icon={<Icons.Pencil />}
                                    index={2}
                                  />
                                </span>
                                <span
                                  onClick={() => handleDelete(item)}
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                >
                                  <CustomTooltip
                                    content={"Delete"}
                                    icon={<Icons.Delete />}
                                    index={3}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {reviews.length === 0 && !loader && (
          <NODataFound
            icon={<Icons.NoAmenity />}
            text="No Reviews Found."
            detail={"There is no review avaialble right now."}
          />
        )}
        {reviews?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>

      {/* EDIT OR DELETE MODAL */}
      <ReviewModal
        isOpen={openAddReviewModal}
        close={() => setOpenAddReviewModal(false)}
        edit={isEdit}
        view={viewOnly}
        review={isEdit || viewOnly ? review : {}}
        getReviews={getReviews}
      />

      {/* DELETE CONFIRMATION */}
      <Confirmation
        isOpen={openDeleteModal}
        text="Are you sure you want to delete this review?"
        loader={loader}
        handleConfirm={deleteSelectedReview}
        close={() => setOpenDeleteModal(false)}
        subText=" Clicking the confirm button will permanently delete the
        selected review."
      />
    </section>
  );
};

export default Reviews;
