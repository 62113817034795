import React from "react";

const SupportTicketLoader = ({ actions }: any) => {
  return (
    <tr className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER">
      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm w-36"></span>
        </span>
      </td>

      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm  w-36"></span>
        </span>
      </td>
      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm  w-36"></span>
        </span>
      </td>

      <td className="px-6 py-4">
        <span role="status" className="space-y-2.5 animate-pulse">
          <span className=" block h-2.5 bg-gray-200 rounded-sm  w-36"></span>
        </span>
      </td>

      <td className="px-6 py-4">
        <span className="flex gap-2 animate-pulse">
          {Array.from({ length: actions }).map((i, index: any) => {
            return (
              <span
                key={index}
                className="block h-6 bg-gray-200   w-6 rounded-full"
              ></span>
            );
          })}
        </span>
      </td>
    </tr>
  );
};

export default SupportTicketLoader;
