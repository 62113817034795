import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Sidebar from "shared/components/sidebar";
import FullPage from "./fullPage";
import { routeConstant } from "./routesConstant";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component, path }: Partial<LayoutProps>) {
  const location = useLocation();
  const currentPath = location.pathname;
  const isGuiderDetailPage = currentPath.includes(
    routeConstant.place_details.path.replace(":id", "")
  );
  const isPlaceDetailPage = currentPath.includes(
    routeConstant.guide_detail.path.replace(":id", "")
  );

  useEffect(() => {
    document.title = title + " | Guide Connect";
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location, location?.pathname]);

  return (
    <>
      {isGuiderDetailPage || isPlaceDetailPage ? (
        <FullPage Component={Component} path={path} />
      ) : (
        <Sidebar Component={Component} path={path} />
      )}
    </>
  );
}

export default Layout;
