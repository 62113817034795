function getWindowDimensions() {
    if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    } else {
        return {
            width: 0,
            height: 0,
        };
    }
}

function roundNum(number: string, decPlaces: number) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= Number(number)) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = String(
                Math.round((Number(number) * decPlaces) / size) / decPlaces
            );

            // Handle special case where we round up to the next abbreviation
            if (Number(number) === 1000 && i < abbrev.length - 1) {
                number = String(1);
                i++;
            }

            // Add the letter for the abbreviation
            number += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return number;
}

const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const handleOpenInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
        newWindow.opener = null; // Prevent new tab from having access to the parent tab
    }
};


export {
    getWindowDimensions,
    roundNum,
    randomIntFromInterval,
    handleOpenInNewTab
};