import classNames from "classnames";
import styles from "./style.module.scss";
import Spinner from "shared/components/spinner";

interface BtnProps extends React.HTMLProps<HTMLButtonElement> {
  containerStyle: any;
  Icon: any;
  iconStyle: any;
  IconDirction: string;
  disabled?: boolean;
  loading?: boolean;
}

const CustomButton = ({
  title,
  onClick,
  containerStyle,
  Icon,
  iconStyle,
  IconDirction,
  disabled,
  style,
  loading,
}: Partial<BtnProps>) => {
  const direction = {
    left: "left",
    right: "right",
  };
  return (
    <button
      className={classNames(
        containerStyle && containerStyle,
        styles.customBtnContainer
      )}
      onClick={onClick}
      disabled={disabled}
      type="submit"
      style={style}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {IconDirction === direction.left ? (
            Icon ? (
              <Icon className={iconStyle ? iconStyle : ""} />
            ) : null
          ) : null}
          <span>{title}</span>
          {IconDirction === direction.right ? (
            Icon ? (
              <Icon className={iconStyle ? iconStyle : ""} />
            ) : null
          ) : null}
        </>
      )}
    </button>
  );
};

export default CustomButton;
