import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  getTermsAndConditions,
  editTermsAndConditions,
} from "shared/services/adminService";
import { modules, formats } from "./constants";
import { toastMessage } from "shared/components/toast";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import SkeletonLoader from "./skeletonLoader";
import { routeConstant } from "shared/routes/routesConstant";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [termsAndConditionsText, setTermsAndConditionsText] =
    useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);

  const handleUpdate = () => {
    let obj: any = {
      text: termsAndConditionsText,
    };
    let formBody = new FormData();

    Object.keys(obj).forEach((key) => {
      formBody.append(key, obj[key]);
    });
    setLoader(true);
    editTermsAndConditions(formBody)
      .then(({ status, data }) => {
        if (status) {
          setLoader(false);
          toastMessage("success", "Terms And Conditions Updated Successfully");
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        toastMessage("error", err?.message);
        setLoader(false);
      });
  };

  const getTerms = () => {
    setLoader(true);
    getTermsAndConditions()
      .then(({ data, status }) => {
        if (status) {
          setTermsAndConditionsText(data?.text);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        toastMessage("error", err?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Terms And Conditions",
            action: () => {
              navigate(routeConstant?.termsAndConditions.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getTerms();
  }, []);
  return (
    <section>
      <div>
        <h1 className="font-semibold tracking-[0.12px] leading-8 text-xl mb-4">
          Terms And Conditions
        </h1>
      </div>
      <div>
        {loader ? (
          <SkeletonLoader />
        ) : (
          <ReactQuill
            value={termsAndConditionsText}
            onChange={(value) => setTermsAndConditionsText(value)}
            modules={modules}
            formats={formats}
            className={`${styles.quillWrapper} font-neueRegular`}
          />
        )}
      </div>
      <div className="flex my-4 justify-end items-center">
        <CustomButton loading={loader} title="Update" onClick={handleUpdate} />
      </div>
    </section>
  );
};

export default TermsAndConditions;
