import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import CustomTableHeader from "shared/components/customTableHeader";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import CustomTooltip from "shared/components/toolTip";
import AddNewPinModal from "shared/modals/addNewPinModal";
import LocationTableSkeleton from "shared/skeletons/locationsTableSkeleton";

import { Icons } from "assets";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { getAllLocations } from "shared/services/adminService";
import { actions, columnHeadings, sortingOptions } from "./constant";

import styles from "./style.module.scss";
import GeneralSkeleton from "shared/skeletons/generalSkeleton";

function Locations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [sort, setSort] = useState<any>("");
  const [addNewPinModal, setAddNewPinModal] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [locations, setLocations] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pendingCount, setPendingCount] = useState<number>(0);

  const viewLocationHandler = (item: any) => {
    navigate(routeConstant.place_details.path.replace(":id", item?._id));
  };

  const handlers = [{ id: "view_location", handler: viewLocationHandler }];

  const getLocations = () => {
    setLoader(true);
    getAllLocations({
      activePage,
      sort: sort?.toString() || "",
      itemsPerPage: 10,
      search: searchText,
    })
      .then((data) => {
        const {
          status,
          data: { count, locations, pendings },
        } = data;
        if (status) {
          setLocations(locations);
          setLoader(false);
          setTotalPages(count);
          setPendingCount(pendings);
        }
      })
      .catch((err) => {
        toastMessage("err", "Failed to load locations");
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Locations",
            action: () => {
              navigate(routeConstant.locations.path);
            },
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    getLocations();
  }, [sort, searchText, activePage]);

  return (
    <section>
      {/* Set Loader Here -----------------*/}
      {loader ? (
        <GeneralSkeleton className="w-full h-32 rounded-lg bg-gray-200 mb-4" />
      ) : (
        <div
          className={`grid grid-cols-1 gap-5 mb-8 ${
            pendingCount > 0 ? " md:grid-cols-2" : " md:grid-cols-1"
          } `}
        >
          {pendingCount > 0 ? (
            <div className="p-8 flex flex-col gap-4  md:items-start bg-LIGHT_ORANGE rounded-lg 2xl:flex-row 2xl:justify-between 2xl:items-center">
              <div className="max-w-[372px] flex flex-col gap-[10px] items-start">
                <h1 className="text-BLACK font-medium text-base md:text-xl">
                  {pendingCount} Location{pendingCount > 1 ? "s" : ""} Request
                  From Guiders
                </h1>
                <p className="text-xs md:text-sm font-normal text-GRAY">
                  Request for location added in map from Guiders / Outfitters
                  showing here. Approve or Reject locations requests.
                </p>
              </div>
              <div>
                <Link
                  className="text-base inline-block whitespace-pre font-medium text-white py-3 px-9 bg-SECONDARY rounded-lg transition-all ease-linear hover:opacity-80"
                  to="/location_requests"
                >
                  View Locations
                </Link>
              </div>
            </div>
          ) : null}
          <div className="p-8 flex flex-col gap-4  md:items-star bg-LIGHT_GREEN rounded-lg 2xl:flex-row 2xl:justify-between 2xl:items-center">
            <div className="max-w-[372px] flex flex-col gap-[10px] items-start">
              <h1 className="text-BLACK font-medium text-base md:text-xl">
                Manage Location & Service Types
              </h1>
              <p className="text-xs md:text-sm font-normal text-GRAY">
                You can set Location types and Service types for users so that
                they can select while adding a new location.
              </p>
            </div>
            <div>
              <Link
                className="text-base inline-block whitespace-pre font-medium text-white py-3 px-9 bg-PRIMARY rounded-lg transition-all ease-linear hover:opacity-80"
                to="/service_types"
              >
                Settings
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* Custom Table Header */}
      <div>
        <CustomTableHeader
          modalButtonText="Add New Pin"
          showFilter={true}
          sortText={sort}
          search={search}
          setSearch={setSearch}
          setSort={setSort}
          setActivePage={setActivePage}
          sortingOptions={sortingOptions}
          heading="All Locations List"
          subHeading="Showing the list of all locations"
          showModalButton={true}
          setOpenModal={setAddNewPinModal}
        />
      </div>

      {/* Locations Table */}
      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div
          className={`relative overflow-x-auto md:overflow-x-auto lg:overflow-x-auto ${styles.hideScrollBar}`}
        >
          <table className="w-full overflow-x-auto text-sm text-left rtl:text-right text-gray-500 min-w-768">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50 ">
              <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                {columnHeadings.map((column: any, index: number) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-xs font-medium text-GRAY"
                    >
                      {column.value}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {loader ? (
                Array.from({ length: 4 }).map((_, index: number) => (
                  <LocationTableSkeleton key={index} actions={1} />
                ))
              ) : (
                <>
                  {locations?.length > 0
                    ? locations?.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY    border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className=" px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <figure className="max-w-12 min-w-12 h-12 rounded-lg overflow-hidden">
                                  <img
                                    alt={item?.title}
                                    loading="lazy"
                                    className="w-full h-full rounded-lg object-cover aspect-[1]"
                                    src={
                                      item?.pictures ? item?.pictures[0] : ""
                                    }
                                  />
                                </figure>
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.title ? item?.title : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div className="flex items-center gap-[10px]">
                                <div className={`w-5 h-5 ${styles.svgWrapper}`}>
                                  <Icons.Address />
                                </div>
                                <span className="font-medium text-xs md:text-sm text-GRAY leading-5">
                                  {item?.address
                                    ? item?.address
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 ">
                              <span className="font-medium text-xs  md:text-sm text-BLACK leading-5">
                                {item?.approved === 0
                                  ? "Pending"
                                  : item?.approved == 1
                                  ? "Approved"
                                  : "Rejected"}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row items-center gap-2">
                                <figure className="max-w-10 max-h-10 rounded-full">
                                  <img
                                    className="w-full h-full rounded-full object-cover aspect-[1]"
                                    alt={item?.title}
                                    src={
                                      item?.created_by
                                        ? item?.created_by
                                            ?.profile_picture_full_path
                                        : null
                                    }
                                    loading="lazy"
                                  />
                                </figure>
                                <span className="font-medium text-xs  md:text-sm text-BLACK leading-5">
                                  {item?.created_by
                                    ? `${item?.created_by.firstname} ${item?.created_by?.lastname}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row gap-2 items-center">
                                {actions?.map((action: any) => {
                                  const handler = handlers?.find(
                                    (handler) => handler?.id === action?.id
                                  )?.handler;
                                  return (
                                    <Link
                                      target="_blank"
                                      to={routeConstant.place_details.path.replace(
                                        ":id",
                                        item?._id
                                      )}
                                      className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                      key={action.id}
                                      // onClick={() => handler?.(item)}
                                    >
                                      <CustomTooltip
                                        content="View Location"
                                        icon={action.icon}
                                        index={item?._id}
                                      />
                                    </Link>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* No Data Found */}
        {locations?.length === 0 && !loader && (
          <NoDataFound icon={<Icons.NoLocation />} />
        )}
        {/* Pagination */}
        <Pagination
          active={activePage}
          setActive={setActivePage}
          totalPages={totalPages}
        />
      </div>

      {/* Add New Pin Modal */}
      <AddNewPinModal
        showModal={addNewPinModal}
        setShowModal={setAddNewPinModal}
      />
    </section>
  );
}

export default Locations;
