export const columnHeadings = [
    { id: "reviewer", value: "Reviewer" },
    { id: "rating", value: "Rating" },
    { id: "date", value: "Date" },
    { id: "review", value: "Review" },
    { id: "action", value: "Actions" },
];

export const sortingOptions = [
    { id: 2, value: 1, text: "Least Rated" },
    { id: 1, value: -1, text: "Top Rated" }
]