const BaseURL = "https://api.guideconnect.org/api/admin";
const OpenURL = "https://api.guideconnect.org/api/";
const GoogleKey = "AIzaSyAri_j-uwabD6Kv5kEjKdlU6sAn7Js_3HA";
const EndPoints = {
  admin: {
    getUserLocations: "/getLocations",
    getRequestedLocation: "/getPendingLocations",
    approveRequestedLocation: "/approveLocation",
    rejectRequestedLocation: "/rejectLocation",
    getAllUsers: "/getAllUsers",
    getTotalCount: "/getTotalCounts",
    activateUser: "/unblockUser",
    deactivateUser: "/blockUser",
    getUserDetails: "/getUserDetails",
    getSingleUserLocation: "/getUserLocations",
    graphData: "/getGraphData",
    dashboardUsers: "getSortedUsers",
    visitedLocations: "getVisitedLocations",
    getLocationDetails: "/getLocDetails",
    changeUserStatus: "/changeUserStatus",
    reportedUsers: "/getReportedUsers",
  },
  auth: {
    login: "/login",
    logout: "/logout",
  },
  profile: {
    updateProfile: "/edit",
    changePassword: "/change-password",
  },
};

export { EndPoints, BaseURL, GoogleKey, OpenURL };
