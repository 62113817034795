import React from "react";

import styles from "./style.module.scss";
import { roundNum } from "shared/utils/helpers";

interface StatsCardProps {
  stats: { title: string; icon: any; value: number; bg_color: string };
  loader: boolean;
}

const StatsCard = ({ stats, loader }: StatsCardProps) => {
  const { icon, title, value } = stats;
  return (
    <div
      style={{ backgroundColor: stats?.bg_color }}
      className="p-6 rounded-lg"
    >
      <div className="flex gap-4 items-start">
        <div className={`w-12 h-12 ${styles.imgWrapper}`}>{icon}</div>
        <div className="flex flex-col gap-2">
          <span className=" text-sm font-medium text-TABLE_BODY_TEXT_GRAY md:text-base">
            {title}
          </span>
          {loader ? (
            <div role="status" className="animate-pulse">
              <div className={`h-3 bg-gray-200 rounded-full  w-20 `}></div>
            </div>
          ) : (
            <span className="text-BLACK tracking-[0.12px] leading-[33px] text-xl font-bold md:text-3xl ">
              {roundNum(value?.toString(), 2)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
