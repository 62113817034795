import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTableHeader from "shared/components/customTableHeader";
import { setBreadCrumb } from "shared/redux/reducers/breadcrumbSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { columnHeadings } from "./constants";
import {
  getAllContactsMessages,
  deleteSupportTicket,
} from "shared/services/adminService";

import styles from "./style.module.scss";
import { toastMessage } from "shared/components/toast";
import NoDataFound from "shared/components/noDataFound";
import { Icons } from "assets/icons";
import Pagination from "shared/components/pagination";
import CustomTooltip from "shared/components/toolTip";
import Confirmation from "shared/modals/confirmation";
import useDebounce from "shared/customHooks/useDebounce";
import SupportTicketLoader from "shared/skeletons/supportTicketLoader";
import ViewSupportMessageModal from "shared/modals/viewSupportMessageModal";

const Contacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [contacts, setContacts] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(10);
  const [ticket, setTicket] = useState<any>({});
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [viewModal, setOpenViewModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: "Support Ticket",
            action: () => {
              navigate(routeConstant.getInTouch.path);
            },
          },
        ],
      })
    );
  }, []);

  const handleView = (ticket: any) => {
    setTicket(ticket);
    setOpenViewModal(true);
  };

  const handleDelete = (ticket: any) => {
    setTicket(ticket);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setLoader(true);
    deleteSupportTicket(ticket?._id)
      .then(({ status, data }) => {
        if (status) {
          toastMessage("success", data);
          setOpenDeleteModal(false);
          setTicket({});
          getContacts();
        }
      })
      .catch((err: any) => {
        console.log("Error---", err);
        toastMessage("error", "Something went wrong!");
      });
  };

  const getContacts = () => {
    setLoader(true);
    getAllContactsMessages({
      search: searchText,
      activePage: activePage,
      itemsPerPage: 10,
    })
      .then(({ status, data }) => {
        if (status) {
          const { supportTickets, count } = data;
          setContacts(supportTickets);
          setTotalPages(count);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error---", err);
        toastMessage("error", "Something went wrong");
      });
  };

  useEffect(() => {
    getContacts();
  }, [searchText, activePage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  return (
    <section>
      <div>
        <CustomTableHeader
          showFilter={false}
          heading="All Support Tickets"
          subHeading="Showing the list of support tickets"
          setSearch={setSearch}
          search={search}
          showModalButton={false}
          setActivePage={setActivePage}
        />
      </div>

      <div className="border border-LOCATION_CARD_BORDER border-t-0 rounded-bl-2xl rounded-br-2xl">
        <div className={`relative overflow-x-auto ${styles.hideScrollBar}`}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 min-w-768">
            {contacts?.length > 0 && (
              <thead className="text-xs text-gray-700 capitalize bg-gray-50  ">
                <tr className="bg-TABLE_HEADER_GRAY border-t border-LOCATION_CARD_BORDER">
                  {columnHeadings.map((column: any) => {
                    return (
                      <th
                        key={column.id}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-GRAY"
                      >
                        {column.value}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {loader ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <SupportTicketLoader key={index} actions={2} />
                ))
              ) : (
                <>
                  {contacts?.length > 0
                    ? contacts?.map((item: any) => {
                        return (
                          <tr
                            key={item?._id}
                            className="bg-white text-sm font-normal text-TABLE_BODY_TEXT_GRAY   border-t border-LOCATION_CARD_BORDER"
                          >
                            <td scope="row" className="px-6 py-4">
                              <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.name ? `${item?.name}` : "Not Provided"}
                              </span>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                  {item?.phone
                                    ? `${item?.phone}`
                                    : "Not Provided"}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.email
                                  ? `${item?.email}`
                                  : "Not Provided"}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              <span className="font-medium text-xs md:text-sm text-BLACK leading-5">
                                {item?.message
                                  ? `${item?.message.slice(0, 30)}...`
                                  : "Not Provided"}
                              </span>
                            </td>
                            <td className="px-6 py-4 ">
                              <div className="flex flex-row gap-2 items-center">
                                <span
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                  onClick={() => handleView(item)}
                                >
                                  <CustomTooltip
                                    content={"View "}
                                    icon={<Icons.ViewIcon />}
                                    index={1}
                                  />
                                </span>
                                <span
                                  className={`hover:cursor-pointer p-2 rounded-full bg-ICON_BACKGROUND_GRAY ${styles.iconWrapper}`}
                                  onClick={() => handleDelete(item)}
                                >
                                  <CustomTooltip
                                    content={"Delete"}
                                    icon={<Icons.Delete />}
                                    index={2}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* NO Data Found */}
        {contacts?.length === 0 && !loader && (
          <NoDataFound icon={<Icons.NoGuider />} />
        )}
        {/* Pagination */}
        {contacts?.length > 0 && totalPages > 0 && (
          <Pagination
            active={activePage}
            setActive={setActivePage}
            totalPages={totalPages}
          />
        )}
      </div>

      {/* DELETE MODAL */}
      <Confirmation
        subText="Clicking the confirm button will permanently delete the selected ticket"
        text="Are you sure you want to delete this ticket?"
        isOpen={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        handleConfirm={handleConfirmDelete}
        loader={loader}
      />

      {/* View Modal */}
      <ViewSupportMessageModal
        close={() => setOpenViewModal(false)}
        message={ticket?.message}
        isOpen={viewModal}
      />
    </section>
  );
};

export default Contacts;
