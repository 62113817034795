import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Icons } from "assets";
import useWindowDimensions from "shared/customHooks/useWindowDimentions";
import CustomButton from "shared/components/customButton";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ImagePreview from "shared/components/imagePreview";

interface ImagesSectionProps {
  list: any[];
}

const ImagesSection = ({ list }: ImagesSectionProps) => {
  const [images, setImages] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const handle = useFullScreenHandle();

  const handleShowPreview = () => {
    handle.enter();
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    handle.exit();
    setShowPreview(false);
  };

  useEffect(() => {
    if (width < 768 && width > 500) {
      setImages(list.slice(1, 4));
    } else if (width > 430 && width < 500) {
      setImages(list.slice(1, 3));
    } else if (width < 430) {
      setImages(list.slice(1, 3));
    } else {
      setImages(list.slice(1, 4));
    }
  }, [width, list]);

  return (
    <FullScreen handle={handle}>
      <section
        className={classNames(
          "flex flex-col md:flex-row items-start gap-3 relative",
          styles.imgSection
        )}
      >
        {list?.length > 1 ? (
          <>
            <div className={classNames(" w-full md:w-3/4")}>
              <div className={classNames("relative", styles.imgContainer)}>
                <img
                  src={list[0]}
                  alt="Slider Main Image"
                  height={"0"}
                  width={"0"}
                  className={classNames(styles.img1)}
                />
                <div className={classNames(styles.magnifyContainer)}>
                  <div
                    className={classNames(styles.iconContainer)}
                    onClick={handleShowPreview}
                  >
                    <Icons.Magnify />
                  </div>
                </div>
                <div className={classNames(styles.btnContainer)}>
                  <CustomButton
                    title="Show all photos"
                    Icon={Icons.Photo}
                    IconDirction="left"
                    containerStyle={classNames(styles.btnStyle)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShowPreview();
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "w-full justify-center md:w-1/4 flex flex-row md:flex-col gap-3 flex-wrap md:justify-between"
              )}
            >
              {images?.map((itm, inx) => {
                return (
                  <div
                    className={classNames(
                      "relative",
                      styles.imgContainer,
                      styles.smallImageWrapper
                    )}
                    key={inx}
                  >
                    <img
                      src={itm}
                      alt=""
                      height={"0"}
                      width={"0"}
                      className={classNames(styles.img2)}
                    />
                    {inx === images?.length - 1 ? (
                      <>
                        {list?.length > 4 ? (
                          <div
                            className={classNames(styles.moreContainer)}
                            onClick={handleShowPreview}
                          >
                            <span>{list?.length - (images?.length + 1)}+</span>
                          </div>
                        ) : (
                          <div className={classNames(styles.magnifyContainer)}>
                            <div
                              className={classNames(styles.iconContainer)}
                              onClick={handleShowPreview}
                            >
                              <Icons.Magnify />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className={classNames(styles.magnifyContainer)}>
                        <div
                          className={classNames(styles.iconContainer)}
                          onClick={handleShowPreview}
                        >
                          <Icons.Magnify />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className={classNames(" w-full ")}>
            <div className={classNames("relative", styles.imgContainer)}>
              <img
                src={images[0]}
                alt=""
                height={"0"}
                width={"0"}
                className={classNames(styles.img1)}
              />
              <div className={classNames(styles.magnifyContainer)}>
                <div className={classNames(styles.iconContainer)}>
                  <Icons.Magnify />
                </div>
              </div>
            </div>
          </div>
        )}
        {showPreview ? (
          <ImagePreview images={list} handleClose={handleClosePreview} />
        ) : null}
      </section>
    </FullScreen>
  );
};

export default ImagesSection;
