import { Icons } from "assets";
import { routeConstant } from "shared/routes/routesConstant";

const adminRoutes = [
  {
    title: "Dashboard",
    path: routeConstant.dashboardAdmin.path,
    icon: <Icons.DashboardIcon />,
  },
  {
    title: "Guiders / Outfitters",
    path: routeConstant.guiders.path,
    icon: <Icons.GuidersIcon2 />,
  },
  {
    title: "Locations",
    path: routeConstant.locations.path,
    icon: <Icons.PinIcon />,
  },
  {
    title: "Manage Amenities",
    path: routeConstant.amenities.path,
    icon: <Icons.Amenities />,
  },
  {
    title: "Manage Reports",
    path: routeConstant.reports.path,
    icon: <Icons.Reports />,
  },
  {
    title: "Manage Reviews",
    path: routeConstant.reviews.path,
    icon: <Icons.Reviews />,
  },
  {
    title: "Support Tickets",
    path: routeConstant.getInTouch.path,
    icon: <Icons.Support />,
  },
  {
    title: "Terms & Privacy",
    icon: <Icons.TermsAndPolicy />,
    subMenu: [
      {
        title: "Terms & Conditions",
        path: routeConstant.termsAndConditions.path,
        subMenuOf: "Terms & Privacy",
      },
      {
        title: "Privacy Policy",
        path: routeConstant.privacyPolicy.path,
        subMenuOf: "Terms & Privacy",
      },
    ],
  },
  {
    title: "Settings",
    path: routeConstant.settings.path,
    icon: <Icons.Settings />,
  },
];

export { adminRoutes };
